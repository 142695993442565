import { Text } from "@mantine/core";

export const ListInsightsCompleted = null;
// <Text>Found key insights!</Text>;

export const ListInsightsAwaitingInput = null;

export const ListInsightsRunning = () => {
  return <Text>We're analyzing patterns...</Text>;
};
