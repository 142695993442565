import type { SupabaseClient } from "@supabase/supabase-js";

export const withAuthTokens = async (supabase: SupabaseClient) => {
  const session = await supabase.auth.getSession();

  if (!session.data.session) {
    throw new Error("No session");
  }

  const { access_token, refresh_token } = session.data.session;

  return {
    header: {
      "sp-access-token": access_token,
      "sp-refresh-token": refresh_token,
    },
  };
};
