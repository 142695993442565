import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Stack,
  Group,
  Text,
  Button,
  Title,
  Center,
  Image,
  Box,
} from "@mantine/core";

import ErrorImage from "@images/error-step.svg";

export const TimeOutError: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Center mt={64}>
      <Group gap={64} align="flex-start">
        <Box>
          <Image w={200} src={ErrorImage} />
        </Box>
        <Stack flex={1}>
          <Title order={2}>Oops! We hit a snag</Title>
          <Text>We couldn't generate an insight this time.</Text>
          <Text>Sometimes complex data needs a fresh perspective.</Text>
          <Text c="dimmed" mt={"lg"}>
            Don't worry! Let's try again with a different approach.
          </Text>
          <Box>
            <Button onClick={() => navigate("/")}>Start a new insight</Button>
          </Box>
        </Stack>
      </Group>
    </Center>
  );
};
