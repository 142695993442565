import { useClient } from "@hooks/use-client";
import { useQuery } from "@tanstack/react-query";

export const useReportsList = () => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["openedReportsList"],
    queryFn: async () => {
      const { data } = await supabase
        .from("reports")
        .select(
          `id,
          name,
          created_at`,
        )
        .order("created_at", { ascending: false });

      return data;
    },
  });
};
