import { Avatar, Card, Group, Table, Text } from "@mantine/core";

const data = [
  {
    avatar: "https://bit.ly/sage-adebayo",
    name: "Justin Rivera",
    genre: "Hip-hop",
    impressions: "2.4M",
    lastActive: "2 days ago",
  },
  {
    avatar: "https://bit.ly/kent-c-dodds",
    name: "Mario Gonzalez",
    genre: "Latin Pop",
    impressions: "1.8M",
    lastActive: "6 days ago",
  },
  {
    avatar: "https://bit.ly/tioluwani-kolawole",
    name: "The Weekenders",
    genre: "Indie Band",
    impressions: "1.2M",
    lastActive: "2 weeks ago",
  },
  {
    avatar: "https://bit.ly/code-beast",
    name: "Eric Chen",
    genre: "Pop",
    impressions: "980K",
    lastActive: "3 weeks ago",
  },
];

export function UsersRolesTable() {
  const rows = data.map((item) => (
    <Table.Tr key={item.name}>
      <Table.Td>
        <Group gap="sm">
          <Avatar size={40} src={item.avatar} radius={40} />
          <div>
            <Text fz="sm" fw={500}>
              {item.name}
            </Text>
            <Text fz="xs" c="dimmed">
              {item.genre}
            </Text>
          </div>
        </Group>
      </Table.Td>

      <Table.Td>{item.impressions}</Table.Td>
      <Table.Td>{item.lastActive}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Card withBorder p="md" radius="md">
      <Table.ScrollContainer>
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Top artists</Table.Th>
              <Table.Th>Ad Impressions</Table.Th>
              <Table.Th>Last stream</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Card>
  );
}
