// this must stay on top
import "./sentry";

import React from "react";
import ReactDOM from "react-dom/client";

import { ClientProvider } from "@hooks/use-client";

import { MantineProvider, MantineThemeProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@xyflow/react/dist/style.css";
import { theme } from "./theme";

import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import Favicon from "@images/brand/trebel-favicon.png";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);
dayjs.extend(relativeTime);

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <title>Trebel Platform</title>
    <meta charSet="UTF-8" />
    <meta name="robots" content="noindex" />
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no"
    />
    <link rel="icon" type="image/svg+xml" href={Favicon} />

    <style type="text/css">
      {`
            html, body, #root {
              margin: 0;
              padding: 0;
              height: 100%;
              min-height: 100vh;
            }

            #root {
              display: flex;
              flex-direction: column;
            }
        `}
    </style>
    <ClientProvider>
      <MantineProvider theme={theme}>
        <Notifications />
        <MantineThemeProvider>
          <App />
        </MantineThemeProvider>
      </MantineProvider>
    </ClientProvider>
  </React.StrictMode>,
);
