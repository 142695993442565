import { queryKeyForInsight, useSuggestions } from "@api/useInsight";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Group,
  Input,
  Stack,
  Text,
  Transition,
} from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";
import type {
  ArtifactSuggestion,
  InsightDb,
  InsightRecordWithArtifacts,
} from "@mm/shared/schemas/insights";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { BiAnalyse, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { usePostMessage } from "../hooks/postMessage";

import classes from "./Chat.module.css";
import { Messages } from "./Messages";

type Props = {
  chatOpened: boolean | undefined;
  setChatOpened: React.Dispatch<React.SetStateAction<boolean>>;
  insightId: InsightDb["id"];
  messages: InsightRecordWithArtifacts[];
};

const BORDER_STYLE = "2px solid var(--mantine-primary-color-6)";

const Suggestions = ({
  insightId,
  suggestions,
  close,
}: {
  insightId: InsightDb["id"];
  suggestions: ArtifactSuggestion[];
  close: () => void;
}) => {
  const { mutate: postMessage } = usePostMessage();
  const queryClient = useQueryClient();

  if (suggestions.length === 0) return null;

  return (
    <Stack style={{ overflow: "hidden" }} gap={0}>
      <Box
        bg={"var(--mantine-primary-color-6)"}
        px={"md"}
        py={2}
        style={{
          alignSelf: "flex-start",
          borderTopLeftRadius: "var(--mantine-radius-sm)",
          borderTopRightRadius: "var(--mantine-radius-sm)",
        }}
      >
        <Text size="sm" c={"white"}>
          Some suggestions
        </Text>
      </Box>
      <Card
        p={0}
        shadow="none"
        bg={"gray.0"}
        style={{
          overflow: "hidden",
          borderTop: BORDER_STYLE,
          borderLeft: BORDER_STYLE,
          borderRight: BORDER_STYLE,
          borderTopLeftRadius: "0",
          borderTopRightRadius: "var(--mantine-radius-md)",
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        }}
      >
        {suggestions.map((suggestion, index) => {
          return (
            <React.Fragment
              key={`${suggestion.impact}_${suggestion.description}`}
            >
              <Group
                gap="xs"
                py={"md"}
                px={"md"}
                className={classes.suggestion}
                style={{ overflow: "hidden", cursor: "pointer" }}
                onClick={() => {
                  // wait a bit and then close the popup
                  setTimeout(close, 200);
                  postMessage(
                    {
                      insightId: insightId,
                      message: `${suggestion.impact}\n\n${suggestion.description}`,
                    },
                    {
                      onSuccess: (_, { insightId }) => {
                        queryClient.invalidateQueries({
                          queryKey: queryKeyForInsight(insightId),
                        });
                      },
                    },
                  );
                }}
              >
                <Stack style={{ alignSelf: "flex-start" }}>
                  <Text size="sm" fw={500}>
                    {index + 1}.
                  </Text>
                </Stack>
                <Stack flex={1} gap={2}>
                  <Text size="sm">{suggestion.impact}</Text>
                  <Text size="sm" c="dimmed">
                    {suggestion.description}
                  </Text>
                </Stack>
                <Group gap={4} className={classes.hidden}>
                  <BiAnalyse color="var(--mantine-primary-color-6)" />
                  <Text size="sm" c="var(--mantine-primary-color-6)">
                    apply
                  </Text>
                </Group>
              </Group>
              {suggestions.length > 1 && index !== suggestions.length - 1 ? (
                <Divider color="var(--mantine-primary-color-6)" size={"2px"} />
              ) : null}
            </React.Fragment>
          );
        })}
      </Card>
    </Stack>
  );
};

export const Chat: React.FC<Props> = ({
  chatOpened,
  setChatOpened,
  insightId,
  messages,
}) => {
  const { mutate: postMessage } = usePostMessage();
  const [value, setValue] = useState("");
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: suggestions, isPending: isSuggestionsLoading } = useSuggestions(
    insightId,
    messages.length,
  );

  const handleSubmit = async () => {
    if (value) {
      try {
        postMessage({ insightId, message: value });
        setValue("");
        setIsSuggestionsVisible(false);
        inputRef.current?.blur();
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsSuggestionsVisible(false);
      }
    };

    const handleInputFocus = () => {
      setIsSuggestionsVisible(true);
    };

    const ref = inputRef.current;
    document.addEventListener("mousedown", handleClickOutside);
    ref?.addEventListener("focus", handleInputFocus);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      ref?.removeEventListener("focus", handleInputFocus);
    };
  }, []);

  const inputStyle = isSuggestionsVisible
    ? {
        borderTop: isSuggestionsLoading
          ? "2px solid var(--mantine-primary-color-6)"
          : "0px solid var(--mantine-primary-color-6)",
        borderBottom: BORDER_STYLE,
        borderLeft: BORDER_STYLE,
        borderRight: BORDER_STYLE,
        borderTopLeftRadius: isSuggestionsLoading
          ? "var(--mantine-radius-md)"
          : 0,
        borderTopRightRadius: isSuggestionsLoading
          ? "var(--mantine-radius-md)"
          : 0,
        borderBottomRightRadius: "var(--mantine-radius-md)",
        borderBottomLeftRadius: "var(--mantine-radius-md)",
        outline: "none",
      }
    : {};

  return (
    <Group flex={0} gap={"xs"} wrap="nowrap">
      <Transition
        mounted={chatOpened ?? false}
        transition="slide-right"
        duration={200}
        timingFunction="ease"
        keepMounted
      >
        {(styles) => (
          <Stack style={styles} pt={20} flex={1} w={"25rem"} h={"100%"}>
            <Messages messages={messages} />
            <Stack mt={"md"} flex={0} pos={"relative"} ref={containerRef}>
              {suggestions && (
                <Collapse
                  in={isSuggestionsVisible}
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    left: 0,
                    right: 0,
                    zIndex: 10,
                  }}
                >
                  <Suggestions
                    close={() => setIsSuggestionsVisible(false)}
                    insightId={insightId}
                    suggestions={suggestions}
                  />
                </Collapse>
              )}
              <Input
                classNames={classes}
                ref={inputRef}
                style={inputStyle}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                size="md"
                rightSectionPointerEvents="all"
                rightSectionWidth={75}
                rightSection={
                  <Button variant="subtle" size="xs" onClick={handleSubmit}>
                    Reply
                  </Button>
                }
                onKeyDown={getHotkeyHandler([
                  ["mod+K", () => setChatOpened(!chatOpened)],
                  ["Enter", handleSubmit],
                ])}
              />
            </Stack>
          </Stack>
        )}
      </Transition>
      <Stack h={"80%"} flex={0} justify="center">
        <Divider mx={"auto"} flex={1} orientation="vertical" />
        <ActionIcon
          flex={0}
          variant="subtle"
          onClick={() => setChatOpened(!chatOpened)}
        >
          {chatOpened ? (
            <BiChevronLeft size={24} />
          ) : (
            <BiChevronRight size={24} />
          )}
        </ActionIcon>
        <Divider mx={"auto"} flex={1} orientation="vertical" />
      </Stack>
    </Group>
  );
};
