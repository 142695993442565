import { Accordion, Group, Text } from "@mantine/core";
import {
  PiCheckCircleDuotone,
  PiCircleDashedDuotone,
  PiHandPointingDuotone,
  PiWarningCircleDuotone,
} from "react-icons/pi";

// Import step-specific components
import {
  AnalyzeRequestAwaitingInput,
  AnalyzeRequestCompleted,
  AnalyzeRequestRunning,
} from "./Steps/AnalyzeRequest";

import { GenerateVisualizationsRunning } from "./Steps/GenerateVisualizations";

import {
  ShortlistColumnsAwaitingInput,
  ShortlistColumnsCompleted,
  ShortlistColumnsRunning,
} from "./Steps/ShortlistColumns";

import {
  CreateProcedureAwaitingInput,
  CreateProcedureCompleted,
  CreateProcedureRunning,
} from "./Steps/CreateProcedure";

import {
  ExecuteProcedureAwaitingInput,
  ExecuteProcedureCompleted,
  ExecuteProcedureRunning,
} from "./Steps/ExecuteProcedure";

import {
  ListInsightsAwaitingInput,
  ListInsightsCompleted,
  ListInsightsRunning,
} from "./Steps/ListInsights";

import dayjs from "dayjs";
import {
  SummarizeInsightsAwaitingInput,
  SummarizeInsightsCompleted,
  SummarizeInsightsRunning,
} from "./Steps/SummarizeInsights";

import {
  FlowSteps,
  isValidStepName,
  type FlowStepName,
} from "@mm/shared/schemas/flows";
import type { FlowStep } from "@mm/shared/schemas/flows";
import { useMemo, useState } from "react";
import styles from "./StepTracker.module.css";
import {
  ExecuteInsightsAwaitingInput,
  ExecuteInsightsCompleted,
  ExecuteInsightsRunning,
} from "./Steps/ExecuteInsights";
import {
  ViewGraphsAwaitingInput,
  ViewGraphsCompleted,
  ViewGraphsRunning,
} from "./Steps/ViewGraphs";

// Define step mapping between DB names and user-friendly labels
const STEPS = {
  [FlowSteps.analyzeRequest]: {
    label: "Understanding Your Goal",
    components: {
      running: AnalyzeRequestRunning,
      awaiting_input: AnalyzeRequestAwaitingInput,
      completed: AnalyzeRequestCompleted,
    },
  },
  [FlowSteps.shortlistColumns]: {
    label: "Finding The Right Data",
    components: {
      running: ShortlistColumnsRunning,
      awaiting_input: ShortlistColumnsAwaitingInput,
      completed: ShortlistColumnsCompleted,
    },
  },
  [FlowSteps.createProcedure]: {
    label: "Planning The Analysis",
    components: {
      running: CreateProcedureRunning,
      awaiting_input: CreateProcedureAwaitingInput,
      completed: CreateProcedureCompleted,
    },
  },
  [FlowSteps.executeProcedure]: {
    label: "Preparing Your Data",
    components: {
      running: ExecuteProcedureRunning,
      awaiting_input: ExecuteProcedureAwaitingInput,
      completed: ExecuteProcedureCompleted,
    },
  },
  [FlowSteps.listInsights]: {
    label: "Spotting Insights Opportunities",
    components: {
      running: ListInsightsRunning,
      awaiting_input: ListInsightsAwaitingInput,
      completed: ListInsightsCompleted,
    },
  },
  [FlowSteps.executeInsights]: {
    label: "Validating Insights",
    components: {
      running: ExecuteInsightsRunning,
      awaiting_input: ExecuteInsightsAwaitingInput,
      completed: ExecuteInsightsCompleted,
    },
  },
  [FlowSteps.summarizeInsights]: {
    label: "Summarizing Key Findings",
    components: {
      running: SummarizeInsightsRunning,
      awaiting_input: SummarizeInsightsAwaitingInput,
      completed: SummarizeInsightsCompleted,
    },
  },
  [FlowSteps.graphProcedure]: {
    label: "Generating visualizations",
    components: {
      running: GenerateVisualizationsRunning,
      awaiting_input: CreateProcedureAwaitingInput,
      completed: CreateProcedureCompleted,
    },
  },
  [FlowSteps.viewGraphs]: {
    label: "Visualizations",
    components: {
      running: ViewGraphsRunning,
      awaiting_input: ViewGraphsAwaitingInput,
      completed: ViewGraphsCompleted,
    },
  },
} as const;

const Step = ({
  dbName,
  step,
}: {
  dbName: FlowStepName;
  step: FlowStep | undefined;
}) => {
  // Default is if step is undefined, and thus "todo"
  let Icon = PiCircleDashedDuotone;
  let iconColor = "var(--mantine-color-dimmed)";
  let color = "var(--mantine-color-dimmed)";
  let RenderComponent:
    | null
    | (({ step }: { step: FlowStep }) => React.ReactNode) = null;

  if (step) {
    switch (step.status) {
      case "completed":
        if (isValidStepName(step.name)) {
          RenderComponent = STEPS[step.name].components[step.status];
          Icon = PiCheckCircleDuotone;
          iconColor = "var(--mantine-color-teal-6)";
          color = "var(--mantine-color-dark)";
        }
        break;
      case "running":
        if (isValidStepName(step.name)) {
          RenderComponent = STEPS[step.name].components[step.status];
          Icon = PiCircleDashedDuotone;
          iconColor = "var(--mantine-primary-color-6)";
          color = "var(--mantine-color-dark)";
        }
        break;
      case "awaiting_input":
        if (isValidStepName(step.name)) {
          RenderComponent = STEPS[step.name].components[step.status];
          Icon = PiHandPointingDuotone;
          iconColor = "var(--mantine-primary-color-6)";
          color = "var(--mantine-color-dark)";
        }
        break;
      case "failed":
        if (isValidStepName(step.name)) {
          RenderComponent = () => <Text>An error occured: {step.error}.</Text>;
          Icon = PiWarningCircleDuotone;
          iconColor = "var(--mantine-color-red-6)";
          color = "var(--mantine-color-dark)";
        }
        break;
      default:
        console.error(`Unknown status ${step.status} for step ${step.id}`);
        // Optionally handle unexpected statuses
        break;
    }
  }
  const label =
    step && isValidStepName(step.name)
      ? STEPS[step.name].label
      : STEPS[dbName].label;

  // Create the component instance and check its output
  const hasContent = RenderComponent !== null;
  return (
    <Accordion.Item value={step?.name ?? dbName}>
      <Accordion.Control
        disabled={!step}
        py={8}
        className={
          !hasContent || step?.status === "running"
            ? styles.disabled
            : undefined
        }
      >
        <Group justify="space-between" pr={"lg"}>
          <Group>
            <Icon
              className={step?.status === "running" ? styles.rotatingIcon : ""}
              size={22}
              color={iconColor}
            />
            <Text size="lg" fw={500} c={color}>
              {label}
            </Text>
          </Group>
          {step?.status === "completed" || step?.status === "failed" ? (
            <Text size="xs" c={"dimmed"}>
              finished in{" "}
              {dayjs(step.updated_at).diff(step.created_at, "second")} seconds
            </Text>
          ) : null}
        </Group>
      </Accordion.Control>
      {hasContent && RenderComponent !== null && step && (
        <Accordion.Panel>
          <RenderComponent step={step} />
        </Accordion.Panel>
      )}
    </Accordion.Item>
  );
};

export const StepTracker = ({ flowSteps }: { flowSteps: FlowStep[] }) => {
  // Track items explicitly opened by user
  const [userOpenedItems, setUserOpenedItems] = useState<string[]>([]);

  const handleChange = (value: string[]) => {
    const newValue = Array.isArray(value) ? value : [value];

    // Find the current running/awaiting step
    const runningStep = flowSteps.find(
      (s) => s.status === "running" || s.status === "awaiting_input",
    );
    const runningStepName = runningStep?.name;

    // Update user-opened items by comparing with current value
    // Exclude the running step from user-opened items
    const newUserOpenedItems = newValue.filter(
      (item) => item !== runningStepName,
    );
    setUserOpenedItems(newUserOpenedItems);
  };

  const activeSteps = useMemo(() => {
    const runningSteps = flowSteps
      .filter((s) => s.status === "running" || s.status === "awaiting_input")
      .map(({ name }) => name);

    if (runningSteps.length > 0) {
      return [...runningSteps, ...userOpenedItems];
    } else {
      return [...userOpenedItems];
    }
  }, [userOpenedItems, flowSteps]);

  return (
    <Accordion
      multiple
      variant="default"
      value={activeSteps}
      onChange={handleChange}
    >
      {Object.keys(STEPS).map((dbName) => (
        <Step
          key={dbName}
          dbName={dbName as FlowStepName}
          step={flowSteps.find((s) => s.name === dbName)}
        />
      ))}
    </Accordion>
  );
};
