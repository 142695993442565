import {
  Badge,
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import type { ReportColumnCandidate } from "@mm/shared/schemas/reports";
import { BiAddToQueue } from "react-icons/bi";
import classes from "./JoinableColumn.module.css";

export const JoinableColumn = ({
  col,
  onClick,
  selected,
}: {
  col: ReportColumnCandidate;
  onClick: () => void;
  selected: boolean;
}) => {
  const className = `${classes.group} ${selected ? classes.selected : ""}`;

  return (
    <Card withBorder className={className} onClick={onClick}>
      <Stack gap={4}>
        <Group style={{ overflow: "hidden" }} gap={"xs"}>
          <ThemeIcon variant="transparent" size={"xs"}>
            <BiAddToQueue />
          </ThemeIcon>
          <Tooltip label={col.name}>
            <Text w={0} flex={1} size="sm" truncate="end">
              {col.name}
            </Text>
          </Tooltip>
          <Badge size="xs" variant="light">
            {col.data_type}
          </Badge>
        </Group>
        <Stack gap={0}>
          <Text size="xs" c={"dimmed"}>
            {col.unique_values} unique values
          </Text>
          <Text size="xs" c={"dimmed"}>
            {col.null_percentage} missing values
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
