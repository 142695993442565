import { Table as MantineTable } from "@mantine/core";
import { flexRender, type Table } from "@tanstack/react-table";

export const TableHeader = <T,>({ table }: { table: Table<T> }) => {
  return (
    <MantineTable.Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <MantineTable.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const value = header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext());

            return <MantineTable.Th key={header.id}>{value}</MantineTable.Th>;
          })}
        </MantineTable.Tr>
      ))}
    </MantineTable.Thead>
  );
};
