import React from "react";

export type MessageComponentType<P> = React.ComponentType<P>;

interface MessageRendererProps<TMessageTypes extends string, TMessageProps> {
  role: string;
  messageComponents: Record<
    TMessageTypes,
    MessageComponentType<TMessageProps & { content: string }>
  >;
  content: string;
  message: TMessageProps;
}

export const MessageRenderer = <TMessageTypes extends string, TMessageProps>({
  role,
  messageComponents,
  content,
  message,
}: MessageRendererProps<
  TMessageTypes,
  TMessageProps
>): React.ReactElement | null => {
  const isSupportedMessage = (message: string): message is TMessageTypes => {
    return message in messageComponents;
  };

  if (!isSupportedMessage(role)) {
    console.warn(`Unknown message role: ${role}`);
    return null;
  }

  const MessageComponent = messageComponents[role];

  return React.createElement(MessageComponent, { ...message, content });
};
