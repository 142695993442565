/**
 * Before typescript 5.5 Map.groupBy was not available, this is a workaround
 * @param list the list to group
 * @param mapper the function to use to map the list to the key
 * @returns a map of the grouped list
 */
export const groupByKey = <T, U extends PropertyKey>(
  list: readonly T[],
  mapper: (_: T) => U,
): Record<U, T[]> => {
  return list.reduce(
    (hash: Record<U, T[]>, obj: T) => ({
      ...hash,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      [mapper(obj)]: (hash[mapper(obj)] || []).concat(obj),
    }),
    {} as Record<U, T[]>,
  );
};

/**
 * Reorder the item at index `startIndex` to index `endIndex`
 * @param list the list to reorder
 * @param firstIndex the original index of the item to move
 * @param secondIndex the target index of the item
 * @returns a new list with the item at index `startIndex` moved to index `endIndex`
 */
export const swapItems = <T>(
  list: readonly T[],
  firstIndex: number,
  secondIndex: number,
): T[] => {
  const result = [...list];
  const [removed] = result.splice(firstIndex, 1);
  if (removed) result.splice(secondIndex, 0, removed);

  return result;
};

/**
 * Randomly selects a specified number of items from an array
 * @template T - The type of elements in the array
 * @param recommendations - The source array to select items from
 * @param limit - The maximum number of items to return
 * @returns A new array containing randomly selected items, with length of min(limit, recommendations.length)
 * @example
 * const items = ['a', 'b', 'c', 'd', 'e'];
 * const selected = selectRandom(items, 3); // Returns array with 3 random items
 */
export const selectRandom = <T>(recommendations: T[], limit: number): T[] => {
  const shuffled = recommendations
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .slice(0, Math.min(limit, recommendations.length))
    .map(({ value }) => value);

  return shuffled;
};

/**
 * Sorts an array of objects based on the value of a specified key.
 * Creates a new sorted array without modifying the original array.
 * @param array - The input array of objects to sort
 * @param key - The key to sort by, must be a property of type T
 * @returns A new sorted array of the same objects
 */
export const sortBy = <T>(array: T[], key: keyof T): T[] => {
  return [...array].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    // Handle different types of values
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.localeCompare(valueB);
    }

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
};
