import { useState } from "react";

import { Box, type BoxProps, rem } from "@mantine/core";

import { InputWithButton } from "@components/InputWithButton";
import { BiRightArrowAlt, BiSolidMagicWand } from "react-icons/bi";

export const Form = ({
  createReport,
  ...props
}: {
  createReport: (starter: string) => Promise<void>;
} & BoxProps) => {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();

    if (!inputValue.trim()) {
      return;
    }

    await createReport(inputValue);
  };

  return (
    <Box {...props}>
      <form onSubmit={(e) => void handleSubmit(e)}>
        <InputWithButton
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          leftIcon={
            <BiSolidMagicWand
              color="var(--mantine-primary-color-6)"
              style={{ width: rem(18), height: rem(18) }}
            />
          }
          buttonSize={32}
          buttonIcon={
            <BiRightArrowAlt style={{ width: rem(18), height: rem(18) }} />
          }
        />
      </form>
    </Box>
  );
};
