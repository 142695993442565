import { Input, ScrollArea, Stack, Text } from "@mantine/core";
import {
  compareCandidates,
  type ReportColumnCandidate,
} from "@mm/shared/schemas/reports";
import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { JoinableColumn } from "./JoinableColumn";

interface JoinableTableProps {
  displayTableName: string;
  selectedColumns: ReportColumnCandidate[];
  columnCandidates: ReportColumnCandidate[];
  selectColumn: (column: ReportColumnCandidate) => void;
  deselectColumn: (column: ReportColumnCandidate) => void;
}

export const JoinableTable: React.FC<JoinableTableProps> = ({
  displayTableName,
  selectedColumns,
  columnCandidates,
  selectColumn,
  deselectColumn,
}) => {
  const [search, setSearch] = useState("");

  const filteredColumns = columnCandidates.filter((col) => {
    const searchLower = search.toLowerCase();
    return col.name.toLowerCase().includes(searchLower);
  });

  const hasColumns = filteredColumns.length > 0;

  return (
    <Stack gap="xs" style={{ overflow: "hidden" }}>
      <Input
        variant="filled"
        size="sm"
        placeholder={`Search columns within ${displayTableName} ...`}
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        leftSection={<BiSearch />}
      />
      <ScrollArea.Autosize scrollbars="y" offsetScrollbars>
        <Stack gap={"md"}>
          {hasColumns ? (
            filteredColumns.map((col) => {
              const isSelected =
                selectedColumns.find((c) => compareCandidates(c, col)) !==
                undefined;
              return (
                <JoinableColumn
                  key={`${col.table_name}-${col.name}`}
                  col={col}
                  selected={isSelected}
                  onClick={() => {
                    if (isSelected) deselectColumn(col);
                    else selectColumn(col);
                  }}
                />
              );
            })
          ) : (
            <Text c="dimmed" size="sm">
              No columns match your search
            </Text>
          )}
        </Stack>
      </ScrollArea.Autosize>
    </Stack>
  );
};
