import { supabase } from "@hooks/use-client";

import {
  ActionIcon,
  Badge,
  Box,
  Center,
  Grid,
  Group,
  Loader,
  Space,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";

import { client } from "@api/client";
import { Form } from "@components/Home/Form";
import { Recommendations } from "@components/Home/Recommendations";
import { Page } from "@layout/Page";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import { StartNewReport } from "@mm/shared/endpoints/companion/reports.routes";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { PiCircleDashed, PiCircleDashedDuotone } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Steps } from "./Steps";

const useStartReport = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (starter: string) => {
      const authHeaders = await withAuthTokens(supabase);

      const { data, error } = await client.POST(StartNewReport.path, {
        params: { ...authHeaders },
        body: {
          starter,
        },
      });

      if (error) {
        throw new Error(`Unable to start report: ${error}`);
      }

      return data;
    },
    onSuccess: (data) => {
      navigate(`/report/${data.id}`);
    },
  });
};

export const Home = () => {
  const { mutate, isPending } = useStartReport();
  const [starter, setStarter] = useState("");

  const createReport = async (selectedStarter: string) => {
    try {
      mutate(selectedStarter);
      setStarter(selectedStarter);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isPending) {
    return (
      <Page rightSection={<Steps active={1} />}>
        <Stack h={"100%"} pb={"sm"} align="center" gap={"xl"}>
          <Badge
            color="var(--mantine-primary-color-6)"
            tt={"none"}
            fw={"normal"}
            radius={"md"}
            size="xl"
            variant="light"
            leftSection={<Loader size={"xs"} mr={"xs"} />}
          >
            Analyzing your request&hellip;
          </Badge>

          <Text size="xl" fw={"bold"}>
            Here is what we'll do together:
          </Text>
          <Stack gap={"xs"} align="flex-end">
            <Tooltip multiline maw={"25rem"} label={starter}>
              <Group gap={4} style={{ cursor: "pointer" }}>
                <Text size="sm" c={"dimmed"}>
                  your request
                </Text>
                <ActionIcon size={"sm"} c="dimmed" variant="transparent">
                  <BiInfoCircle size={16} />
                </ActionIcon>
              </Group>
            </Tooltip>
            <Grid
              w={900}
              gutter={0}
              style={{ borderRadius: "var(--mantine-radius-md)" }}
              bd={"1px solid var(--mantine-color-gray-5)"}
            >
              <Grid.Col span={4}>
                <Box h={"100%"} p={"sm"}>
                  <Group align="flex-start" gap={"xs"}>
                    <PiCircleDashedDuotone
                      size={28}
                      color="var(--mantine-primary-color-5)"
                    />
                    <Stack flex={1} gap={"xs"}>
                      <Text size="lg" fw={700}>
                        Set your goal
                      </Text>
                      <Text>
                        I will define the objectives based on your request
                      </Text>
                    </Stack>
                  </Group>
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <Box
                  h={"100%"}
                  p={"sm"}
                  style={{
                    borderLeft: "1px solid var(--mantine-color-gray-5)",
                    borderRight: "1px solid var(--mantine-color-gray-5)",
                  }}
                >
                  <Group align="flex-start" gap={"xs"}>
                    <PiCircleDashed
                      size={28}
                      color="var(--mantine-color-gray-5)"
                    />
                    <Stack flex={1} gap={"xs"}>
                      <Text size="lg" fw={700}>
                        Prepare data
                      </Text>
                      <Text>
                        Based on these objectives, we will plan and prepare the
                        data together
                      </Text>
                    </Stack>
                  </Group>
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <Box h={"100%"} p={"sm"}>
                  <Group align="flex-start" gap={"xs"}>
                    <PiCircleDashed
                      size={28}
                      color="var(--mantine-color-gray-5)"
                    />
                    <Stack flex={1} gap={"xs"}>
                      <Text size="lg" fw={700}>
                        Draw insights
                      </Text>
                      <Text>
                        From the prepared data, we will decide on the analysis
                        to perform
                      </Text>
                    </Stack>
                  </Group>
                </Box>
              </Grid.Col>
            </Grid>
          </Stack>
        </Stack>
      </Page>
    );
  }

  return (
    <Page>
      <Center flex={1}>
        <Stack
          h={"100%"}
          w={750}
          py={"xl"}
          align="center"
          justify="space-between"
        >
          <Stack gap={"xs"} w={"100%"}>
            <Text fz={24} fw={"bold"}>
              Analyze your artists performance
            </Text>
            <Space h={"xl"} />
            <Recommendations createReport={createReport} />
          </Stack>
          <Form w={550} createReport={createReport} />
        </Stack>
      </Center>
    </Page>
  );
};
