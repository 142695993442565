import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import { GoldViewDataSchema } from "@mm/shared/schemas/gold";
import { GoldViewColumnsSchema } from "@mm/shared/schemas/gold/structure";
import {
  cursorParams,
  filterQueryParams,
  limitQueryParams,
  orderQueryParams,
} from "../utils";

export const GoldViewIdParam = z
  .object({ goldViewId: z.string().pipe(z.coerce.number()) })
  .openapi("GoldViewId", { type: "integer" });

export const RetrieveGoldViewColumns = createRoute({
  method: "get",
  path: "/api/v1/gold/views/{goldViewId}/columns",
  operationId: "retrieveGoldViewColumns",
  request: {
    headers: SupabaseHeaderSchema,
    params: GoldViewIdParam,
  },
  responses: {
    200: {
      description: "A list containing the columns of the gold view",
      content: {
        "application/json": {
          schema: GoldViewColumnsSchema,
        },
      },
    },
    404: {
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
      description: "Unable to retrieve gold view with this id",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const RetrieveGoldViewData = createRoute({
  method: "get",
  path: "/api/v1/gold/views/{goldViewId}/data",
  operationId: "companionRetrieveGoldViewData",
  request: {
    headers: SupabaseHeaderSchema,
    params: GoldViewIdParam,
    query: z.object({
      limit: limitQueryParams,
      order: orderQueryParams,
      cursor: cursorParams,
      filter: filterQueryParams,
    }),
  },
  responses: {
    200: {
      description: "The content of the gold view",
      content: {
        "application/json": {
          schema: GoldViewDataSchema,
        },
      },
    },
    404: {
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
      description: "Unable to retrieve gold view with this id",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const ExportGoldViewData = createRoute({
  method: "post",
  path: "/api/v1/gold/views/{goldViewId}/export",
  operationId: "exportGoldViewData",
  request: {
    headers: SupabaseHeaderSchema,
    params: GoldViewIdParam,
    query: z.object({
      limit: limitQueryParams,
    }),
  },
  responses: {
    200: {
      content: {
        "text/csv": {
          schema: z.string(),
        },
      },
      headers: {
        "Content-Type": {
          schema: {
            type: "string",
          },
        },
        "Content-Disposition": {
          schema: {
            type: "string",
          },
          description: "Attachment; filename=exportedData.csv",
        },
      },
      description: "Exported data",
    },
  },
});
