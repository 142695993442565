import { z } from "@hono/zod-openapi";
import type { IsEqual } from "type-fest";
import type { Database } from "./supabase/database.types";
import { transformationSchema } from "../data/transform";

export const FlowStepSchema = z.object({
  created_at: z.string(),
  error: z.string().nullable(),
  flow_id: z.string(),
  id: z.number(),
  name: z.string(),
  output: z.object({}).passthrough().or(z.unknown().array()).nullable(),
  // must match Database["public"]["Enums"]["step_status"], see test below
  status: z.enum(["running", "completed", "failed", "awaiting_input"]),
  updated_at: z.string().nullable(),
  user_input: z.string().nullable(),
});

export const FlowSchema = z.object({
  created_at: z.string(),
  id: z.string(),
  request: z.string(),
  user_id: z.string().nullable(),
  flow_steps: z.array(FlowStepSchema),
});

export const ExecuteProcedureOutputSchema = z
  .object({
    view_id: z.string(),
  })
  .transform(({ view_id }) => {
    const [project, dataset, table] = view_id.split(".");
    return { project, dataset, table };
  });

export type FlowStep = z.infer<typeof FlowStepSchema>;
export type Flow = z.infer<typeof FlowSchema>;

type AssertTrue<A extends true> = A;

// @ts-expect-error making sure that the type are aligned
// eslint-disable-next-line
type _Test1 = AssertTrue<
  IsEqual<FlowStep["status"], Database["public"]["Enums"]["step_status"]>
>;

export const FlowDataSchema = z
  .object({})
  .passthrough()
  .array()
  .openapi("FlowData");

export type FlowTableData = z.infer<typeof FlowDataSchema>;

export const FlowColumnsSchema = z
  .object({
    name: z.string(),
    data_type: z.string(),
  })
  .array()
  .openapi("FlowColumns");

export type FlowColumns = z.infer<typeof FlowColumnsSchema>;

// Flow step output schemas:
//
export const AnalyzeRequestSchema = z.object({
  interpretations: z.string().array(),
});

export const ShortlistColumnsSchema = z.number().array();

export const SummarizeInsightsSchema = z.object({
  findings: z.string().array(),
});

export const VisualizationConfigGenerationSchema = z.object({
  transformation: transformationSchema.array(),
  plot_configuration: z
    .object({
      type: z.string(),
      label: z.string(),
    })
    .passthrough(),
});

export type VisualizationConfigGeneration = z.infer<
  typeof VisualizationConfigGenerationSchema
>;

export const FlowSteps = {
  analyzeRequest: "analyze_request",
  shortlistColumns: "shortlist_columns",
  createProcedure: "create_procedure",
  executeProcedure: "execute_procedure",
  listInsights: "list_insights",
  executeInsights: "execute_insights",
  summarizeInsights: "summarize_insights",
  graphProcedure: "visualization_procedure",
  viewGraphs: "visualization_config_generation",
} as const;

export type FlowStepName = (typeof FlowSteps)[keyof typeof FlowSteps];

export const isValidStepName = (name: string): name is FlowStepName => {
  return Object.values(FlowSteps).includes(name as FlowStepName);
};

export const STEP_ORDER = [
  FlowSteps.analyzeRequest,
  FlowSteps.shortlistColumns,
  FlowSteps.createProcedure,
  FlowSteps.executeProcedure,
  FlowSteps.listInsights,
  FlowSteps.executeInsights,
  FlowSteps.summarizeInsights,
  FlowSteps.graphProcedure,
  FlowSteps.viewGraphs,
] as const;

export const getStepOrder = (step: FlowStepName): number => {
  return STEP_ORDER.indexOf(step);
};

export const isStepBefore = (
  stepA: FlowStepName,
  stepB: FlowStepName,
): boolean => {
  return getStepOrder(stepA) < getStepOrder(stepB);
};

export const lastStepIndex = STEP_ORDER.length - 1;
