import type { NodeTypes } from "@xyflow/react";
import { TemplateNode } from "./TemplateNode";

export const nodeTypes: NodeTypes = {
  source: (props) => <TemplateNode title={"Source"} {...props} />,
  table: (props) => <TemplateNode title={"Table"} {...props} />,
  column: (props) => <TemplateNode title={"Column"} {...props} />,
  transform: (props) => <TemplateNode title={"Transform"} {...props} />,
  join: (props) => <TemplateNode title={"Join"} {...props} />,
  outcome: (props) => <TemplateNode title={"Outcome"} {...props} />,
};
