import { Center, Stack, Text } from "@mantine/core";
import { groupByKey } from "@mm/shared/utils/collections";
import { Line } from "react-chartjs-2";
import { z } from "zod";
import { infiniteColorStream } from "./utils";

export const LineDataFormatParser = z.object({
  label: z.string(),
  label2: z.string().optional(),
  data: z.number().or(z.string()).pipe(z.coerce.number()),
});

type LineDataFormat = z.infer<typeof LineDataFormatParser>;

interface Props {
  label: string;
  data: LineDataFormat[];
}

const LINE_TENSION = 0.3;

const convertToLine = (_label: string, inputData: Props["data"]) => {
  const colorGenerator = infiniteColorStream();
  const uniqueOrdered = inputData.reduce<string[]>((unique, item) => {
    return unique.includes(item.label) ? unique : [...unique, item.label];
  }, []);

  let dataset = [];

  const hasTwoAxis = inputData.some(({ label2 }) => label2 != undefined);

  if (hasTwoAxis) {
    const groupedByLabel2 = groupByKey(
      inputData,
      ({ label2 }) => label2 ?? "missing label",
    );
    const colors = Object.fromEntries(
      Object.entries(groupedByLabel2).map(([lable2]) => {
        return [lable2, colorGenerator.next().value];
      }),
    );

    dataset = Object.entries(groupedByLabel2).map(([label, items]) => ({
      label: label,
      orderColor: colors[label],
      backgroundColor: colors[label],
      data: uniqueOrdered.map((label) => {
        const matchingItem = items.find((item) => item.label === label);
        return matchingItem ? matchingItem.data : 0;
      }),
      tension: LINE_TENSION,
    }));
  } else {
    const data = inputData.map(({ data }) => data);
    const color = colorGenerator.next().value;
    dataset = [
      {
        label: _label,
        data: data,
        borderColor: color,
        backgroundColor: color,
        tension: LINE_TENSION,
      },
    ];
  }

  return {
    labels: uniqueOrdered,
    datasets: dataset,
  };
};

export const LineChart: React.FC<Props> = ({ label, data }) => {
  console.log("Line", convertToLine(label, data));
  return (
    <Stack>
      <Line data={convertToLine(label, data)} />
      <Center mt="md" mb="xl">
        <Text>{label}</Text>
      </Center>
    </Stack>
  );
};
