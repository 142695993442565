import { z } from "@hono/zod-openapi";

export const limitQueryParams = z
  .string()
  .pipe(z.coerce.number())
  .optional()
  .openapi({
    param: {
      required: false,
    },
    type: "integer",
  });

export const orderQueryParams = z
  .string()
  .transform((str) => {
    if (!str) return [];
    return str.split(",").map((part) => {
      const [column, direction = "ASC"] = part.split(":");
      return {
        column,
        direction: direction.toUpperCase() as "ASC" | "DESC",
      };
    });
  })
  .pipe(
    z.array(
      z.object({
        column: z.string(),
        direction: z.enum(["ASC", "DESC"]),
      }),
    ),
  )
  .optional()
  .openapi({
    param: {
      required: false,
      example: "name:asc,date:desc",
    },
    type: "string",
  });

export const cursorParams = z
  .string()
  .pipe(z.coerce.number())
  .optional()
  .openapi({
    param: {
      required: false,
    },
    type: "number",
  });

export const filterQueryParams = z
  .string()
  .transform((str) => {
    if (!str) return [];
    return str.split(",").map((part) => {
      const [column, value = ""] = part.split(":").map(decodeURIComponent);
      return { column, value };
    });
  })
  .pipe(
    z.array(
      z.object({
        column: z.string(),
        value: z.string(),
      }),
    ),
  )
  .optional()
  .openapi({
    param: {
      required: false,
      example: "status:active,name:John%20Doe",
    },
    type: "string",
  });
