import dayjs from "@mm/shared/utils/daysjs";

/**
 * This module handles the formatting and normalization of table cell values for display and sorting.
 *
 * Problem:
 * When displaying data in tables, we often receive values in various formats (strings, dates,
 * JSON objects, etc.). We need to:
 * 1. Display these values in a consistent, human-readable format
 * 2. Enable proper sorting of these values regardless of their original format
 *
 * Solution:
 * The FormattedData type and formatCellValue function transform any input value into a
 * standardized format with two properties:
 * - display: A string representation suitable for rendering
 * - sortValue: A normalized value (string/number/date) suitable for consistent sorting
 *
 * The module handles various special cases:
 * - JSON strings (parsing them to extract actual values)
 * - Date/time values (converting to consistent format)
 * - BigQuery-style objects with 'value' property
 * - Nested objects (pretty-printing them)
 * - Null/undefined values
 */

export type FormattedData = {
  display: (string & {}) | "empty" | null;
  sortValue: string | number | Date | null;
};

export const isFormattedData = (x: unknown): x is FormattedData => {
  return typeof x == "object" && !!x && "display" in x && "sortValue" in x;
};

const MIN_VALID_YEAR = 1900;
const MAX_VALID_YEAR = 2200;

const DATE_FORMATS = [
  // ISO 8601 formats
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
  "YYYY-MM-DDTHH:mm:ssZ",
  "YYYY-MM-DDTHH:mmZ",
  "YYYY-MM-DDTHH:mm:ss",
  "YYYY-MM-DDTHH:mm",
  "YYYY-MM-DDT",
  // Standard date formats
  "YYYY-MM-DD",
  "YYYY/MM/DD",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "MM-DD-YYYY",
  "MM/DD/YYYY",
  // Date time formats
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "YYYY/MM/DD HH:mm:ss",
  "YYYY/MM/DD HH:mm",
  "DD-MM-YYYY HH:mm:ss",
  "DD-MM-YYYY HH:mm",
  "DD/MM/YYYY HH:mm:ss",
  "DD/MM/YYYY HH:mm",
  "MM-DD-YYYY HH:mm:ss",
  "MM-DD-YYYY HH:mm",
  "MM/DD/YYYY HH:mm:ss",
  "MM/DD/YYYY HH:mm",
] as const;

export const isDateString = (value: unknown): value is string => {
  if (typeof value !== "string") return false;

  // Remove common date separators to check if it's just numbers
  const stripped = value.replace(/[-/.:\s]/g, "");
  if (/^\d+$/.test(stripped)) {
    // If it's all numbers, ensure it matches common date patterns
    // Prevent pure numbers from being interpreted as timestamps
    if (stripped.length < 6) return false; // Too short to be a date
    if (value.length === stripped.length) return false; // No separators = probably not a date
  }

  // First try parsing as ISO 8601
  if (value.includes("T")) {
    const isoDate = dayjs(value);
    if (isoDate.isValid()) {
      const year = isoDate.year();
      return year >= MIN_VALID_YEAR && year <= MAX_VALID_YEAR;
    }
  }

  // Then try other formats
  return DATE_FORMATS.some((format) => {
    const date = dayjs(value, format, true); // Strict parsing
    if (date.isValid()) {
      // Additional validation for reasonable dates
      const year = date.year();
      if (year < MIN_VALID_YEAR || year > MAX_VALID_YEAR) return false;

      return true;
    }
    return false;
  });
};

/*
 * For each cell of our table, we need to be able to:
 * - show its value (properly formatted)
 * - compare it to other values of the value column (for sorting)
 *
 * This function return this information for any given cell
 */
export const formatDataValue = (
  value: unknown,
  { replaceNullValue }: { replaceNullValue: boolean } = {
    replaceNullValue: true,
  },
): FormattedData => {
  if (value === null || value === undefined) {
    return {
      display: replaceNullValue ? "empty" : null,
      sortValue: null,
    };
  }

  let processedValue: unknown = value;

  /*
   * We sometimes receives data as serialized JSON object from BigQuery.
   * This is for instance the case of all date object, but not only.
   * Here we try to parse the data as JSON. In case of error we return
   * the value as a plain string
   */
  if (typeof processedValue === "string") {
    try {
      const parsed = JSON.parse(processedValue) as unknown;
      /*
       * This part is very important and might become an issue in the future:
       * As we are parsing the input as JSON, if we had the string value "1234"
       * it would become the number 1234.
       *
       * This might not be an issue but it's something to keep in mind.
       * Tests are covering this
       */
      processedValue = parsed;
    } catch {
      /* */
    }
  }

  /* Now that our JSON object have been parsed, we can check if they match a specific
   * format we want to deal with. This could contains formatting for a specific lib (i.e BigQuery)
   * or more generic formatting for object
   */
  if (
    processedValue &&
    typeof processedValue === "object" &&
    Object.keys(processedValue).length === 1 &&
    "value" in processedValue
  ) {
    const extractedValue = processedValue.value;

    const asDate = isDateString(extractedValue)
      ? dayjs(extractedValue)
      : undefined;

    if (asDate && asDate.isValid()) {
      return {
        display: asDate.format("YYYY-MM-DD HH:mm:ss"),
        sortValue: asDate.valueOf(),
      };
    }

    return {
      display: String(extractedValue),
      sortValue: extractedValue as string | number,
    };
  }

  /* The object we extracted  did not match any format of data we expected
   * so we continue with the initial data were we can also applied library specific
   * and/or generic formatting on the value
   */
  const asDate = isDateString(processedValue)
    ? dayjs(processedValue)
    : undefined;

  if (asDate && asDate.isValid()) {
    return {
      display: asDate.format("YYYY-MM-DD HH:mm:ss"),
      sortValue: asDate.valueOf(),
    };
  }

  /*
   * Sometimes the value we want to show can be a JSON and we want to avoid the cursed
   * [Object object] so we stringify the result.
   * It does not really make any sense to sort those kind of object, but we default to
   * string comparison
   */
  if (typeof processedValue === "object") {
    const stringified = JSON.stringify(processedValue, null, 2);
    return {
      display: stringified,
      sortValue: stringified,
    };
  }

  if (typeof processedValue === "boolean") {
    return {
      display: String(processedValue),
      sortValue: processedValue ? 1 : 0,
    };
  }

  if (typeof processedValue === "number") {
    return {
      display: Number.isInteger(processedValue)
        ? String(processedValue)
        : processedValue.toFixed(2),
      sortValue: processedValue,
    };
  }

  // we don't really know what we are getting, default to string
  return {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    display: String(processedValue),
    sortValue: processedValue as string | number,
  };
};
