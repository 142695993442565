import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";

export const GetSubscriptionStatus = createRoute({
  method: "get",
  path: "/api/v1/subscription-status",
  operationId: "retrieveSubscriptionStatus",
  request: {
    headers: SupabaseHeaderSchema,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            isSubscribed: z.boolean(),
            preferences: z
              .object({})
              .passthrough()
              .describe("knock preference description"),
          }),
        },
      },
      description: "Retrieve information about user subscription status",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const NotifSubscribe = createRoute({
  method: "post",
  path: "/api/v1/subscribe",
  operationId: "notificationSubscribe",
  request: {
    headers: SupabaseHeaderSchema,
    query: z.object({ channelType: z.string().optional() }),
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            success: z.boolean(),
            subscribed: z.boolean(),
          }),
        },
      },
      description: "Subscribe to a new channel type",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const NotifUnsubscribe = createRoute({
  method: "post",
  path: "/api/v1/unsubscribe",
  operationId: "notificationUnsubscribe",
  request: {
    headers: SupabaseHeaderSchema,
    query: z.object({ channelType: z.string().optional() }),
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            success: z.boolean(),
            subscribed: z.boolean(),
          }),
        },
      },
      description: "Unsubscribe to a new channel type",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
