import { Loader, Switch } from "@mantine/core";
import { BiBell, BiBellOff } from "react-icons/bi";

interface NotificationsSwitchProps {
  toggleSubscription: (newState: boolean) => void;
  isTogglingSubscription: boolean;
  isLoadingSubscription: boolean;
  isSubscribed: boolean;
}

export const NotificationsSwitch = ({
  toggleSubscription,
  isTogglingSubscription,
  isLoadingSubscription,
  isSubscribed,
}: NotificationsSwitchProps) => {
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleSubscription(event.currentTarget.checked);
  };

  if (isLoadingSubscription) {
    return <Loader size="xs" />;
  }

  return (
    <Switch
      size="md"
      onLabel={<BiBell size={16} />}
      offLabel={<BiBellOff size={16} />}
      checked={isSubscribed}
      onChange={handleToggle}
      disabled={isTogglingSubscription}
    />
  );
};
