import { List, Stack, Text } from "@mantine/core";
import {
  SummarizeInsightsSchema,
  type FlowStep,
} from "@mm/shared/schemas/flows";

export const SummarizeInsightsCompleted = ({ step }: { step: FlowStep }) => {
  const { data: findings } = SummarizeInsightsSchema.safeParse(step.output);

  return (
    <Stack>
      <Text>Your key findings are ready:</Text>
      <List spacing="lg" center>
        {findings?.findings.map((finding) => (
          <List.Item key={finding}>
            <Text>{finding}</Text>
          </List.Item>
        ))}
      </List>
      <Text>Here's a chart to help you visualize the data:</Text>
    </Stack>
  );
};

export const SummarizeInsightsAwaitingInput = null;

export const SummarizeInsightsRunning = () => {
  return <Text>We're putting insights into words...</Text>;
};
