import { Box, Group, Paper, Progress, SimpleGrid, Text } from "@mantine/core";
import { BiAnalyse, BiRightTopArrowCircle } from "react-icons/bi";
import classes from "./StatsSegments.module.css";

const data = [
  { label: "LATAM", count: "17M", part: 53, color: "#47d6ab" }, // Latin America
  { label: "NORTH AM", count: "12M", part: 38, color: "#03141a" }, // North America
  { label: "EUROPE", count: "3M", part: 9, color: "#4fcdf7" }, // Europe
];

export function StatsSegments() {
  const segments = data.map((segment) => (
    <Progress.Section
      value={segment.part}
      color={segment.color}
      key={segment.color}
    >
      {segment.part > 10 && <Progress.Label>{segment.part}%</Progress.Label>}
    </Progress.Section>
  ));

  const descriptions = data.map((stat) => (
    <Box
      key={stat.label}
      style={{ borderBottomColor: stat.color }}
      className={classes.stat}
    >
      <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
        {stat.label}
      </Text>

      <Group justify="space-between" align="flex-end" gap={0}>
        <Text fw={700}>{stat.count}</Text>
        <Text c={stat.color} fw={700} size="sm" className={classes.statCount}>
          {stat.part}%
        </Text>
      </Group>
    </Box>
  ));

  return (
    <Paper withBorder p="md" radius="md" h={"100%"}>
      <Group justify="space-between">
        <Group align="flex-end" gap="xs">
          <Text fz="xl" fw={700}>
            32M
          </Text>
          <Text c="teal" className={classes.diff} fz="sm" fw={700}>
            <span>18%</span>
            <BiRightTopArrowCircle size={16} style={{ marginBottom: 4 }} />
          </Text>
        </Group>
        <BiAnalyse size={22} className={classes.icon} />
      </Group>

      <Text c="dimmed" fz="sm">
        Total streams, compared to previous month
      </Text>

      <Progress.Root
        size={34}
        classNames={{ label: classes.progressLabel }}
        mt={40}
      >
        {segments}
      </Progress.Root>
      <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
        {descriptions}
      </SimpleGrid>
    </Paper>
  );
}
