import { z } from "zod";
import { LLMReportColumn } from "../schemas/reports/reportSchema";

// Verify results types
const Node = z.object({
  id: z.string(),
  type: z.enum(["source", "table", "column", "transform", "join", "outcome"]),
  explanation: z.string(),
});

const Edge = z.object({
  source: z.string(),
  target: z.string(),
});

export const LLMVerifyResultsResponse = z.object({
  generalAnalysis: z.object({
    summary: z.string(),
    implementation: z.array(z.string()),
    assumptions: z.array(
      z.object({
        type: z.enum([
          "grain",
          "completeness",
          "relationship",
          "transformation",
          "other",
        ]),
        details: z.string(),
        explanation: z.string(),
      }),
    ),
  }),
  codeAnalysis: z.array(
    z.object({
      chunkTitle: z.string(),
      chunkExplanation: z.string(),
      lines: z.array(
        z.object({
          sql: z.string(),
          explanation: z.string(),
        }),
      ),
    }),
  ),
  flowAnalysis: z.object({
    nodes: z.array(Node),
    edges: z.array(Edge),
  }),
});

export type LLMVerifyResultsResponseType = z.infer<
  typeof LLMVerifyResultsResponse
>;

export const LLMColumnDependency = z.object({
  name: z.string(),
  table_name: z.string(),
});

export const LLMAnalyzeStarterResponse = z.object({
  name: z.string(),
  goal: z.string(),
  report_purpose: z.string(),
  analysis_plan: z.string(),
  explanation: z.string(),
  columns: z.array(LLMReportColumn),
});

export const ColumnToRemove = LLMReportColumn.pick({
  name: true,
  table_name: true,
});

export const ColumnToReplace = z.object({
  source_column: ColumnToRemove.describe(
    "Column to be removed from the selection in favor of a new column",
  ),
  new_column: LLMReportColumn.describe("New column to add in the selection"),
});

export const LLMInsightResponse = z.object({
  text: z.string(),
  reflection: z.string().optional(), // Backward compatible before it was introduced
});

export const LLMArtifactGeneratedSql = z.object({
  sql: z.string(),
  name: z.string(),
  description: z.string(),
});

export type ColumnToRemoveType = z.infer<typeof ColumnToRemove>;

export type LLMAnalyzeStarterResponseType = z.infer<
  typeof LLMAnalyzeStarterResponse
>;

export type ColumnToReplaceType = z.infer<typeof ColumnToReplace>;

export type LLMInsightResponseType = z.infer<typeof LLMInsightResponse>;

export type LLMInsightGeneratedSqlType = z.infer<
  typeof LLMArtifactGeneratedSql
>;
