import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import type { Report, ReportColumnCandidate } from "@mm/shared/schemas/reports";
import { LLMResponseWithReportSuggestions } from "@mm/shared/schemas/reports/reportSchema";

const ReportIdParam = z
  .object({ reportId: z.string().pipe(z.coerce.number()) })
  .openapi("ReportId", { type: "integer" });

export const GetReport = createRoute({
  method: "get",
  path: "/api/v1/reports/{reportId}",
  operationId: "getReport",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          // @todo: remove this ugly casting and write a proper parser instead
          schema: z.unknown().transform((x) => x as Report),
        },
      },
      description: "Return a report",
    },
  },
});

export const GetReportColumnOptions = createRoute({
  method: "get",
  path: "/api/v1/reports/{reportId}/columns/options",
  operationId: "getReportColumnOptions",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            joinableColumns: z
              .unknown()
              // @todo: remove this ugly casting and write a proper parser instead
              .transform((x) => x as ReportColumnCandidate[]),
          }),
        },
      },
      description: "The list of column that can be added to the report",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

const ReportInputSchema = z.object({
  starter: z.string(),
});

export const StartNewReport = createRoute({
  method: "post",
  path: "/api/v1/reports/start",
  operationId: "startReport",
  request: {
    headers: SupabaseHeaderSchema,
    body: {
      required: true,
      content: {
        "application/json": {
          schema: ReportInputSchema,
        },
      },
    },
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({ id: z.number() }),
        },
      },
      description: "return the id of the newly created report",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

const goldViewResultParser = z.object({
  goldViewId: z.number(),
});

export const GenerateReport = createRoute({
  method: "post",
  path: "/api/v1/reports/{reportId}/generate",
  operationId: "generateReport",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: goldViewResultParser,
        },
      },
      description: "Materialize a gold view from the definition of a report",
    },

    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const AddColumnToReport = createRoute({
  method: "post",
  path: "/api/v1/reports/{reportId}/columns",
  operationId: "addColumnToReport",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
    body: {
      required: true,
      content: {
        "application/json": {
          schema: z.object({
            columns: z
              .unknown()
              .array()
              // @todo remove whenever we have a proper parser for ReportColumnCandidate
              .transform((x) => x as ReportColumnCandidate[]),
          }),
        },
      },
    },
  },
  responses: {
    200: {
      description: "",
      content: {
        "application/json": {
          schema: z.object({ reportId: z.number() }),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

const ReportInputChatMessage = z.object({ userMessage: z.string() });

export const SendReportChatMessage = createRoute({
  method: "post",
  path: "/api/v1/reports/{reportId}/chat",
  operationId: "sendReportChatMessage",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
    body: {
      required: true,
      content: {
        "application/json": {
          schema: ReportInputChatMessage,
        },
      },
    },
  },
  responses: {
    200: {
      description: "Response from the LLM, including some suggestions",
      content: {
        "application/json": {
          schema: LLMResponseWithReportSuggestions,
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
