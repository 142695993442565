import { useSubscription } from "./useSubscription";
import { Card, Center, Group, Loader, Stack, Text } from "@mantine/core";
import { NotificationsSwitch } from "./NotificationsSwitch";
import { ChannelNotificationSwitch } from "./ChannelNotificationSwitch";

export const Notifications = () => {
  const {
    toggleSubscription,
    isSubscribed,
    isLoadingSubscription,
    isTogglingSubscription,
  } = useSubscription();

  return (
    <Card padding="lg">
      <Card.Section withBorder inheritPadding py="xs">
        <Group justify="space-between">
          <Text fw={500}>Notifications</Text>
          <NotificationsSwitch
            toggleSubscription={(newState) =>
              toggleSubscription({ subscribed: newState })
            }
            isTogglingSubscription={isTogglingSubscription}
            isLoadingSubscription={isLoadingSubscription}
            isSubscribed={isSubscribed}
          />
        </Group>
      </Card.Section>
      <Card.Section withBorder inheritPadding py="lg">
        {isLoadingSubscription || isTogglingSubscription ? (
          <Center>
            <Loader />
          </Center>
        ) : !isSubscribed ? (
          <Center>
            <Text c={"dimmed"}>Notifications disabled</Text>
          </Center>
        ) : (
          <Stack gap={"xl"}>
            <ChannelNotificationSwitch channelType="email" label="E-mail" />
            <ChannelNotificationSwitch
              channelType="push"
              label="Browser"
              disabled
            />
            <ChannelNotificationSwitch
              channelType="chat"
              label="Slack"
              disabled
            />
            <ChannelNotificationSwitch
              channelType="chat"
              label="Microsoft Teams"
              disabled
            />
          </Stack>
        )}
      </Card.Section>
    </Card>
  );
};
