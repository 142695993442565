import { Outlet, Navigate } from "react-router-dom";

import { useClient } from "@hooks/use-client";

import { Center, useMantineTheme } from "@mantine/core";

export const Auth = () => {
  const theme = useMantineTheme();
  const { user } = useClient();

  if (user.data) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Center flex={1} bg={theme.colors.gray[0]}>
      <Outlet />
    </Center>
  );
};
