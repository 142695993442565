import { Center, Loader, ScrollArea, Stack } from "@mantine/core";
import { useEffect, useRef } from "react";
import type { BaseMessage } from "./BaseTypes";
import { MessageRenderer, type MessageComponentType } from "./MessageRenderer";

interface ChatContainerProps<
  TMessage extends BaseMessage,
  TMessageTypes extends string,
> {
  messages: TMessage[];
  loading?: boolean;
  emptyState?: React.ReactNode;
  messageComponents: Record<
    TMessageTypes,
    MessageComponentType<TMessage & { content: string }>
  >;
  scrollAreaProps?: React.ComponentProps<typeof ScrollArea>;
  onMessagesRendered?: (
    containerRef: React.RefObject<HTMLDivElement | null>,
  ) => void;
}

export const ChatContainer = <
  TMessage extends BaseMessage,
  TMessageTypes extends string,
>({
  messages = [],
  loading = false,
  emptyState,
  messageComponents,
  scrollAreaProps = {},
  onMessagesRendered,
}: ChatContainerProps<TMessage, TMessageTypes>) => {
  const messagesRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesRef.current?.clientHeight, messages]);

  useEffect(() => {
    if (messagesRef.current) {
      onMessagesRendered?.(messagesRef);
    }
  }, [onMessagesRendered]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (messages.length === 0 && emptyState) {
    return <Center flex={1}>{emptyState}</Center>;
  }

  return (
    <ScrollArea
      flex={1}
      viewportRef={messagesRef}
      scrollbars="y"
      offsetScrollbars
      {...scrollAreaProps}
    >
      <Stack gap="md" pr={8} w="100%" style={{ overflow: "hidden" }}>
        {messages.map((message) => {
          const content = message.message.content;
          let messageContent = "";

          if (!content) return null;

          if (typeof content === "string") {
            messageContent = content;
          } else if (
            Array.isArray(content) &&
            content[0] &&
            "text" in content[0]
          ) {
            messageContent = content[0].text;
          } else {
            console.warn(`Unknown message content type:`, content);
            return null;
          }

          return (
            <MessageRenderer
              key={message.id}
              role={message.display_role}
              content={messageContent}
              message={message}
              messageComponents={messageComponents}
            />
          );
        })}
      </Stack>
    </ScrollArea>
  );
};
