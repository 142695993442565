import createClient, { type Middleware } from "openapi-fetch";
import { API_URL } from "../../config";
import type { paths } from "./v1";

const apiClient = createClient<paths>({ baseUrl: API_URL });

const throwOnError: Middleware = {
  async onResponse({ response }) {
    if (response.status >= 400) {
      const body = response.headers.get("content-type")?.includes("json")
        ? ((await response.clone().json()) as unknown)
        : await response.clone().text();
      if (typeof body === "string") {
        throw new Error(body);
      } else {
        throw new Error("Something wrong happened");
      }
    }
    return undefined;
  },
};

apiClient.use(throwOnError);

export const client = apiClient;
