import { ActionIcon, TextInput, type TextInputProps } from "@mantine/core";
import type { JSX, RefObject } from "react";
import { BiQuestionMark } from "react-icons/bi";

interface TextInputWithLoadingProps extends TextInputProps {
  loading?: boolean;
  leftIcon?: JSX.Element;
  buttonSize?: number;
  buttonIcon: JSX.Element;
  innerRef?: RefObject<HTMLInputElement>;
}

export const InputWithButton = ({
  disabled = false,
  loading = false,
  leftIcon = undefined,
  buttonSize = 32,
  buttonIcon = <BiQuestionMark />,
  innerRef = undefined,
  ...rest
}: TextInputWithLoadingProps) => {
  return (
    <TextInput
      ref={innerRef}
      disabled={disabled}
      radius="sm"
      size="lg"
      placeholder="Looking for something else? Ask here"
      rightSectionWidth={48}
      leftSection={!!leftIcon && leftIcon}
      rightSection={
        <ActionIcon
          disabled={disabled}
          type="submit"
          size={buttonSize}
          variant="light"
          loading={loading}
        >
          {buttonIcon}
        </ActionIcon>
      }
      {...rest}
    />
  );
};
