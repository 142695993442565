import { z } from "zod";

export const LLMColumnDependency = z.object({
  name: z.string(),
  table_name: z.string(),
});
export type LLMColumnDependencyType = z.infer<typeof LLMColumnDependency>;

export const LLMReportColumn = z.object({
  name: z.string(),
  table_name: z.string().nullable(),
  explanation: z.string(),
  transformation_description: z.string().nullable().optional(),
  dependencies: z.array(LLMColumnDependency).nullable().optional(),
});
export type LLMReportColumnType = z.infer<typeof LLMReportColumn>;

export const LLMResponseWithReportSuggestions = z.object({
  text: z.string(),
  suggestions: z.array(LLMReportColumn),
  reflection: z.string(),
});
export type LLMReportSuggestionsType = z.infer<
  typeof LLMResponseWithReportSuggestions
>;
