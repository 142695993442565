const colors = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

/**
 *
 * @param availableColors hex code for the available colors
 * @yields an hex color code
 */
export function* infiniteColorStream(
  availableColors: string[] = colors,
): Generator<string> {
  const usedColors = new Set<string>();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  while (true) {
    if (usedColors.size === availableColors.length) {
      usedColors.clear();
    }

    const unusedColor = availableColors.find((nextColor) => {
      return !usedColors.has(nextColor);
    })!;

    usedColors.add(unusedColor);
    yield unusedColor;
  }
}
