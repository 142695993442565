import { Center, Stack, Text } from "@mantine/core";
import { Doughnut } from "react-chartjs-2";
import { z } from "zod";
import { infiniteColorStream } from "./utils";

export const DoughnutDataFormatParser = z.object({
  label: z.string(),
  data: z.number().or(z.string()).pipe(z.coerce.number()),
});

type DoughnutDataFormat = z.infer<typeof DoughnutDataFormatParser>;

interface Props {
  label: string;
  data: DoughnutDataFormat[];
}

const convertToDoughnut = (label: string, inputData: DoughnutDataFormat[]) => {
  const colorGenerator = infiniteColorStream();
  const data = inputData.map(({ data }) => data);
  const labels = inputData.map(({ label }) => label);
  const colors = data.map(() => colorGenerator.next().value);

  return {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        borderColor: colors,
        backgroundColor: colors,
      },
    ],
  };
};

export const DoughnutChart: React.FC<Props> = ({ label, data }) => {
  return (
    <Stack>
      <Doughnut data={convertToDoughnut(label, data)} />
      <Center mt="md" mb="xl">
        <Text>{label}</Text>
      </Center>
    </Stack>
  );
};
