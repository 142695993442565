import {
  type Session,
  type SupabaseClient,
  type User,
  createClient,
} from "@supabase/supabase-js";
import React, { createContext, useContext, useEffect, useState } from "react";

import type { Database } from "@mm/shared/schemas/supabase/database.types";

export const supabase = createClient<Database>(
  import.meta.env.VITE_SUPABASE_API_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

interface UserData {
  data?: User;
  session?: Session;
  loading: boolean;
}

export type ContextType = {
  supabase: SupabaseClient<Database>;
  user: UserData;
  setUser: (user: UserData) => void;
};

const ClientContext = createContext<ContextType>({
  supabase: supabase,
  user: {
    data: undefined,
    session: undefined,
    loading: true,
  },
  setUser: () => {
    /* do nothing */
  },
});

export const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserData>({
    data: undefined,
    session: undefined,
    loading: true,
  });

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      setUser({
        data: session?.user ?? undefined,
        session: session ?? undefined,
        loading: false,
      });
    };
    fetchSession().catch(console.error);

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, session) => {
      setUser({
        data: session?.user ?? undefined,
        session: session ?? undefined,
        loading: false,
      });
    });

    return () => subscription.unsubscribe();
  }, []);

  const value = {
    supabase,
    user,
    setUser,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => useContext(ClientContext);
