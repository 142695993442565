import { Table } from "@components/Table";
import { Page } from "@layout/Page";
import React from "react";

import { ActionIcon, Box, Text, Tooltip } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { BiWindowOpen } from "react-icons/bi";
import { NavLink } from "react-router-dom";

import { useReportsList } from "../api/useReportsList";

type TableItem = Exclude<
  ReturnType<typeof useReportsList>["data"],
  undefined | null
>[number];

export const ReportsList: React.FC = () => {
  const { data, isPending } = useReportsList();

  const columnHelper = createColumnHelper<TableItem>();

  const columns = [
    columnHelper.display({
      id: "report",
      cell: (props) => (
        <Tooltip label="Open reports">
          <ActionIcon
            variant="light"
            component={NavLink}
            to={`/report/${props.row.original.id}`}
          >
            <BiWindowOpen />
          </ActionIcon>
        </Tooltip>
      ),
    }),
    columnHelper.accessor("name", {
      cell: (info) => (
        <Box display={"flex"}>
          <Tooltip multiline maw={"25rem"} label={info.getValue()}>
            <Text truncate="end">{info.getValue()}</Text>
          </Tooltip>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Name
        </Text>
      ),
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => (
        <Box display={"flex"}>
          <Text truncate="end">
            {dayjs(info.getValue()).utc().format("DD MMM. YYYY [at] HH:mm")}
          </Text>
        </Box>
      ),
      header: () => (
        <Text fw="bold" truncate="end">
          Created at
        </Text>
      ),
    }),
  ];

  return (
    <Page
      title={
        <Text size="xl" fw={"900"}>
          List of data preparations
        </Text>
      }
    >
      <Table data={data ?? undefined} isPending={isPending} columns={columns} />
    </Page>
  );
};
