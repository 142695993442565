import { Box, Card, Stack, Text } from "@mantine/core";
import { MessageContent } from "./MessageContent";

export const UserMessage = ({ content }: { content: string }) => {
  return (
    <Stack style={{ overflow: "hidden" }} gap={0} align="flex-end">
      <Box
        bg={"gray.8"}
        px={"md"}
        py={2}
        style={{
          borderTopLeftRadius: "var(--mantine-radius-sm)",
          borderTopRightRadius: "var(--mantine-radius-sm)",
        }}
      >
        <Text size="sm" c={"white"}>
          You
        </Text>
      </Box>
      <Card
        miw={100}
        shadow="none"
        bg={"gray.0"}
        style={{
          overflow: "hidden",
          border: "2px solid var(--mantine-color-gray-8)",
          borderTopRightRadius: "0",
          borderTopLeftRadius: "var(--mantine-radius-md)",
          borderBottomRightRadius: "var(--mantine-radius-md)",
          borderBottomLeftRadius: "var(--mantine-radius-md)",
        }}
        ta={"right"}
      >
        <MessageContent>{content}</MessageContent>
      </Card>
    </Stack>
  );
};
