import { z } from "@hono/zod-openapi";

export const GoldViewColumnsSchema = z
  .object({
    name: z.string(),
  })
  .array()
  .openapi("GoldViewColumns");

export type GoldViewColumns = z.infer<typeof GoldViewColumnsSchema>;
