import { groupByKey } from "@mm/shared/utils/collections";
import { Bar } from "react-chartjs-2";
import { z } from "zod";
import { infiniteColorStream } from "./utils";
import { Center, Stack, Text } from "@mantine/core";

export const BarDataFormatParser = z.object({
  label: z.string(),
  label2: z.string().optional(),
  data: z.number().or(z.string()).pipe(z.coerce.number()),
});

type BarDataFormat = z.infer<typeof BarDataFormatParser>;

interface Props {
  label: string;
  data: BarDataFormat[];
}

const convertToBar = (label: string, inputData: BarDataFormat[]) => {
  const colorGenerator = infiniteColorStream();
  let dataset = [];
  const uniqueOrdered = inputData.reduce<string[]>((unique, item) => {
    return unique.includes(item.label) ? unique : [...unique, item.label];
  }, []);

  const hasTwoAxis = inputData.some(({ label2 }) => label2 != undefined);
  if (hasTwoAxis) {
    const groupedByLabel2 = groupByKey(
      inputData,
      ({ label2 }) => label2 ?? "missing label",
    );

    const colors = Object.fromEntries(
      Object.entries(groupedByLabel2).map(([lable2]) => {
        return [lable2, colorGenerator.next().value];
      }),
    );

    dataset = Object.entries(groupedByLabel2).map(([region, items]) => ({
      label: region,
      orderColor: colors[region],
      backgroundColor: colors[region],
      data: uniqueOrdered.map((label) => {
        const matchingItem = items.find((item) => item.label === label);
        return matchingItem ? matchingItem.data : 0;
      }),
    }));
  } else {
    const data = inputData.map(({ data }) => data);
    const color = colorGenerator.next().value;
    dataset = [
      {
        label: label,
        data: data,
        borderColor: color,
        backgroundColor: color,
      },
    ];
  }

  // Transform into desired format
  return {
    label: label,
    labels: uniqueOrdered,
    datasets: dataset,
  };
};

export const BarChart: React.FC<Props> = ({ label, data }) => {
  return (
    <Stack>
      <Bar data={convertToBar(label, data)} />
      <Center mt="md" mb="xl">
        <Text>{label}</Text>
      </Center>
    </Stack>
  );
};
