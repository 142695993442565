import { Group, Stack, Text } from "@mantine/core";
import { NavbarSearch } from "./NavbarSearch";

interface PageProps {
  fullWidth?: boolean;
  title?: React.ReactNode;
  rightSection?: React.ReactNode;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({
  fullWidth = false,
  title,
  rightSection,
  children,
}) => {
  return (
    <Group
      h={"100vh"}
      w={"100%"}
      wrap="nowrap"
      style={{ overflow: "hidden", alignItems: "stretch" }}
    >
      <NavbarSearch />
      <Stack
        flex={1}
        w={fullWidth ? "100%" : "90%"}
        style={{ overflow: "hidden", alignItems: "stretch" }}
        py={10}
      >
        <Group
          wrap="nowrap"
          h={"xl"}
          gap={"xl"}
          justify="space-between"
          align="center"
          style={{ overflow: "hidden" }}
          px={"lg"}
        >
          <Text span>{title}</Text>
          {rightSection}
        </Group>
        {children}
      </Stack>
    </Group>
  );
};
