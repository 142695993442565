import { supabase } from "@hooks/use-client";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import { GetArtifact } from "@mm/shared/endpoints/companion/artifacts.routes";
import { useQuery } from "@tanstack/react-query";
import { client } from "./client";

const queryKeyForArtifact = (artifactId: number) => {
  return ["artifacts", { artifactId }];
};

export const useArtifact = (artifactId: number | null) => {
  return useQuery({
    enabled: !!artifactId,
    queryKey: [...queryKeyForArtifact(Number(artifactId))],
    staleTime: Infinity,
    queryFn: async () => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.GET(GetArtifact.path, {
        params: {
          ...authHeaders,
          path: {
            artifactId: String(artifactId),
          },
        },
      });

      if (error) {
        console.error(
          `Unable to get artifact id: ${artifactId}: ${error.error}`,
        );
        throw new Error(`Unable to get artifact id: ${artifactId}`);
      }

      return GetArtifact.responses[200].content[
        "application/json"
      ].schema.parse(data);
    },
  });
};
