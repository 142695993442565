import {
  type Edge,
  type Node,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import React, { useEffect } from "react";
import { nodeTypes } from "./Nodes";
import { useLayoutedElements } from "./useLayoutedElements";

interface InnerLayoutFlowProps {
  initialNodes: Node[];
  initialEdges: Edge[];
}

export const InnerLayoutFlow: React.FC<InnerLayoutFlowProps> = ({
  initialNodes,
  initialEdges,
}) => {
  const { onLayout } = useLayoutedElements();

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);
  const { fitView } = useReactFlow();

  useEffect(() => {
    onLayout("LR");
  }, [onLayout]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      fitView({ padding: 0 }).catch(() => {
        /* do nothing */
      });
    });
  }, [nodes, fitView]);

  return (
    <ReactFlow
      fitView
      fitViewOptions={{ padding: 0 }}
      nodesDraggable={false}
      edgesFocusable={false}
      nodesFocusable={false}
      elementsSelectable={false}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      defaultEdgeOptions={{
        style: { strokeWidth: 1.75, stroke: "black" },
        type: "smoothstep",
      }}
      defaultMarkerColor="black"
    />
  );
};
