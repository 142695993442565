import Dagre from "@dagrejs/dagre";
import { useReactFlow, type Edge, type Node } from "@xyflow/react";
import { useCallback } from "react";

interface LayoutOptions {
  direction: "TB" | "LR";
}

export const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, setEdges, fitView } = useReactFlow();

  const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[],
    options: LayoutOptions,
  ) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction, nodesep: 25, ranksep: 75 });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
      g.setNode(node.id, {
        ...node,
        width: node.measured?.width ?? 300,
        height: node.measured?.height ?? 175,
      }),
    );

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const { x, y } = g.node(node.id);
        return {
          ...node,
          position: {
            x: x - (node.measured?.width ?? 300) / 2,
            y: y - (node.measured?.height ?? 175) / 2,
          },
        };
      }),
      edges,
    };
  };

  const onLayout = useCallback(
    (direction: LayoutOptions["direction"]) => {
      const nodes = getNodes();
      const edges = getEdges();
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, { direction });

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);

      window.requestAnimationFrame(() => {
        fitView({ padding: 40 });
      });
    },
    [getNodes, getEdges, setNodes, setEdges, fitView],
  );

  return { onLayout };
};
