import {
  Table as MantineTable,
  Pagination,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  formatDataValue,
  isFormattedData,
  type FormattedData,
} from "@mm/shared/data/FormattedData";
import type { GoldViewTableData } from "@mm/shared/schemas/gold";
import type { GoldViewColumns } from "@mm/shared/schemas/gold/structure";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type OnChangeFn,
  type Row,
  type SortingState,
} from "@tanstack/react-table";
import React, { useCallback, useMemo } from "react";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

const MAX_CELL_CHAR_LENGTH = 35;
/*
 * Compare the values from `rowA` and `rowB` for column `columnId`
 */
const sortingFn = (
  rowA: Row<Record<string, FormattedData>>,
  rowB: Row<Record<string, FormattedData>>,
  columnId: string,
) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);

  const sortValueA = isFormattedData(a) ? a.sortValue : null;
  const sortValueB = isFormattedData(b) ? b.sortValue : null;

  // Handle null values
  if (sortValueA === null && sortValueB === null) return 0;
  if (sortValueA === null) return -1;
  if (sortValueB === null) return 1;

  // Handle dates
  if (sortValueA instanceof Date && sortValueB instanceof Date) {
    return sortValueA.getTime() - sortValueB.getTime();
  }

  // Handle numbers
  if (typeof sortValueA === "number" && typeof sortValueB === "number") {
    return sortValueA - sortValueB;
  }

  // Default string comparison
  return String(sortValueA).localeCompare(String(sortValueB));
};

// Display component that just renders the pre-formatted display value
const CellContent: React.FC<{
  formattedValue: FormattedData;
}> = ({ formattedValue: { display } }) => {
  if (display === "empty") {
    return (
      <Text c="dimmed" size="sm" truncate="end">
        empty
      </Text>
    );
  }

  if (display && display.length > MAX_CELL_CHAR_LENGTH) {
    return (
      <Tooltip multiline maw="25rem" label={display}>
        <Text truncate="end">{display}</Text>
      </Tooltip>
    );
  }

  return <Text truncate="end">{display}</Text>;
};

const headerContent =
  ({ name }: { name: string }) =>
  () => {
    return (
      <Tooltip multiline maw="25rem" label={name}>
        <Text fw="bold" truncate="end">
          {name}
        </Text>
      </Tooltip>
    );
  };

export type TableFilters = Record<string, string | number>;

type TableProps = {
  dataColumns: GoldViewColumns | undefined;
  data: GoldViewTableData | undefined;
  onSortingChange: (sorting: SortingState) => void;
  onFiltersChange: (filters: TableFilters) => void;
  sorting: SortingState;
  filters: TableFilters;
  emptyTable: React.ReactNode;
};

const columnHelper = createColumnHelper<Record<string, FormattedData>>();

export const Table: React.FC<TableProps> = ({
  dataColumns,
  data,
  onSortingChange,
  onFiltersChange,
  filters,
  sorting,
  emptyTable,
}) => {
  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newSorting =
        typeof updater === "function" ? updater(sorting) : updater;
      onSortingChange(newSorting);
    },
    [sorting, onSortingChange],
  );

  const handleFiltersChange = useCallback(
    (newFilters: TableFilters) => {
      onFiltersChange(newFilters);
    },
    [onFiltersChange],
  );

  // Process the data once, memoizing both display and sort values
  const processedData = useMemo(() => {
    if (!data) return [];

    return data.map((row) => {
      const processedRow: Record<string, FormattedData> = {};

      for (const [key, value] of Object.entries(row)) {
        processedRow[key] = formatDataValue(value);
      }

      return processedRow;
    });
  }, [data]);

  const columns = useMemo(() => {
    if (dataColumns && dataColumns.length > 0) {
      return dataColumns.map(({ name }) =>
        columnHelper.accessor(name, {
          cell: (info) => <CellContent formattedValue={info.getValue()} />,
          header: headerContent({ name }),
          sortingFn: sortingFn,
        }),
      );
    }
    return [];
  }, [dataColumns]);

  const table = useReactTable({
    data: processedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sorting,
    },
    manualSorting: true,
    onSortingChange: handleSortingChange,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  return (
    <Stack flex={1} miw={0} gap={0}>
      <MantineTable.ScrollContainer minWidth={0}>
        <MantineTable
          bg={"white"}
          verticalSpacing="xs"
          withColumnBorders
          withRowBorders
          withTableBorder
        >
          <TableHeader
            table={table}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
          <TableBody table={table} />
        </MantineTable>
      </MantineTable.ScrollContainer>
      {/* Do not render the empty status inside the table */}
      {data && !data.length ? (
        emptyTable
      ) : (
        <Stack align="flex-end" gap={4}>
          <Text c="dimmed" size="sm">
            {table.getRowCount()} rows in total
          </Text>
          {table.getPageCount() > 1 && (
            <Pagination
              size="xs"
              total={table.getPageCount()}
              siblings={2}
              boundaries={2}
              value={table.getState().pagination.pageIndex + 1}
              onChange={(val) => table.setPageIndex(val - 1)}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};
