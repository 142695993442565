import { Box, Card, Skeleton, Stack, Text } from "@mantine/core";

export const WaitingMessage = () => {
  return (
    <Stack style={{ overflow: "hidden" }} gap={0}>
      <Box
        bg={"gray.8"}
        px={"md"}
        py={2}
        style={{
          alignSelf: "flex-start",
          borderTopLeftRadius: "var(--mantine-radius-sm)",
          borderTopRightRadius: "var(--mantine-radius-sm)",
        }}
      >
        <Text size="sm" c={"white"}>
          Assistant
        </Text>
      </Box>
      <Card
        shadow="none"
        bg={"gray.0"}
        style={{
          overflow: "hidden",
          border: "2px solid var(--mantine-color-gray-8)",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "var(--mantine-radius-md)",
          borderBottomRightRadius: "var(--mantine-radius-md)",
          borderBottomLeftRadius: "var(--mantine-radius-md)",
        }}
      >
        <Stack gap={"xs"}>
          <Text size="xs" c={"dimmed"}>
            MageMetrics is writing&hellip;
          </Text>
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} width={"70%"} radius="xl" />
        </Stack>
      </Card>
    </Stack>
  );
};
