import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import {
  ArtifactSuggestionsSchema,
  type InsightDb,
  type InsightList,
} from "@mm/shared/schemas/insights";
import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";

export const ListInsights = createRoute({
  method: "get",
  path: "/api/v1/insights",
  operationId: "listInsights",
  request: {
    headers: SupabaseHeaderSchema,
  },
  responses: {
    200: {
      description: "Retrieve the list of insight",
      content: {
        "application/json": {
          // @todo: replace this with proper parser when we have one for InsightList.
          // The parser for InsightLight depends on the parser for Insight which relies
          // on the one for Report, that we don't currently have.
          schema: z.unknown().transform((x) => x as InsightList),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export type InsightResponse = {
  insight: InsightDb;
  status: "final" | "pending";
};

const InsightId = z.object({ insightId: z.string().pipe(z.coerce.number()) });
const ArtifactId = z.object({ artifactId: z.string().pipe(z.coerce.number()) });

export const GetInsight = createRoute({
  method: "get",
  path: "/api/v1/insights/{insightId}",
  operationId: "getInsight",
  request: {
    headers: SupabaseHeaderSchema,
    params: InsightId,
  },
  responses: {
    200: {
      description: "Retrieve a given insight",
      content: {
        "application/json": {
          // @todo: replace this with proper parser when we have one for InsightDb.
          schema: z.unknown().transform((x) => x as InsightResponse),
        },
      },
    },
    400: {
      description: "Orphaned Insight. The Insight must belongs to a report",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

const InsightInputChatMessage = z.object({ userMessage: z.string() });

export const SendInsightChatMessage = createRoute({
  method: "post",
  path: "/api/v1/insights/{insightId}/chat",
  operationId: "sendInsightChatMessage",
  request: {
    headers: SupabaseHeaderSchema,
    params: InsightId,
    body: {
      required: true,
      content: {
        "application/json": {
          schema: InsightInputChatMessage,
        },
      },
    },
  },
  responses: {
    200: {
      description: "Send a message to the insight's chat",
      content: {
        "application/json": {
          // @todo: replace this with proper parser when we have one
          schema: z.custom<ChatCompletionMessageParam>(),
        },
      },
    },
    400: {
      description: "Orphaned Insight. The Insight must belongs to a report",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const RestartInsight = createRoute({
  method: "post",
  path: "/api/v1/insights/{insightId}/restart",
  operationId: "restartInsight",
  request: {
    headers: SupabaseHeaderSchema,
    params: InsightId,
  },
  responses: {
    200: {
      description: "Restart the insight at the beginning",
      content: {
        "application/json": {
          // @todo: replace this with proper parser when we have one
          schema: z.custom<InsightResponse>(),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const RestartInsightAtArtifact = createRoute({
  method: "post",
  path: "/api/v1/insights/{insightId}/restart/{artifactId}",
  operationId: "restartInsightAtArtifact",
  request: {
    headers: SupabaseHeaderSchema,
    params: InsightId.merge(ArtifactId),
  },
  responses: {
    200: {
      description: "Restart the insight at the given artifact",
      content: {
        "application/json": {
          // @todo: replace this with proper parser when we have one
          schema: z.unknown().transform((x) => x as InsightResponse),
        },
      },
    },
    400: {
      description: "The artifact does not belongs to the insight",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const GetSuggestionsForInsight = createRoute({
  method: "post",
  path: "/api/v1/insights/{insightId}/suggestions",
  operationId: "getSuggestionsForInsight",
  request: {
    headers: SupabaseHeaderSchema,
    params: InsightId,
  },
  responses: {
    200: {
      description: "Retrieve suggestions for the given insight",
      content: {
        "application/json": {
          schema: ArtifactSuggestionsSchema,
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
