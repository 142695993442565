import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";
import type { Simplify } from "type-fest";
import type { Database } from "./supabase/database.types";
import { z } from "zod";

const JsonSchema = z.union([
  z.null(),
  z.array(z.record(z.unknown())),
  z.record(z.unknown()),
]);

export const safeParseMetadata = (data: unknown) => {
  if (
    typeof data === "string" ||
    typeof data === "number" ||
    typeof data === "boolean" ||
    data === null ||
    data === undefined
  ) {
    return null;
  }

  const result = JsonSchema.safeParse(data);

  if (!result.success) {
    return null;
  }

  return result.data;
};

export type InsightRecordDb = Simplify<
  Omit<Database["public"]["Tables"]["insight_records"]["Row"], "message"> & {
    message: ChatCompletionMessageParam;
  }
>;

export type GoldViewDb = Simplify<
  Omit<Database["public"]["Tables"]["gold_views"]["Row"], "metadata"> & {
    metadata:
      | Record<PropertyKey, unknown>
      | Record<PropertyKey, unknown>[]
      | null;
  }
>;

export type ArtifactDb = Simplify<
  Omit<Database["public"]["Tables"]["artifacts"]["Row"], "data_sample"> & {
    data_sample: Record<string, unknown>[] | null;
  }
>;

export type InsightRecordWithArtifacts = InsightRecordDb & {
  artifacts: ArtifactDb[];
};

export type InsightDb = Simplify<
  GoldViewDb & {
    report: Database["public"]["Tables"]["reports"]["Row"] | null;
    messages: InsightRecordWithArtifacts[];
  }
>;

export type InsightList = Simplify<
  GoldViewDb & {
    report: Database["public"]["Tables"]["reports"]["Row"] | null;
    artifacts: ArtifactDb[];
  }
>[];

export const ArtifactSuggestionsSchema = z
  .object({
    impact: z.string(),
    description: z.string(),
  })
  .array();

export type ArtifactSuggestion = z.infer<
  typeof ArtifactSuggestionsSchema
>[number];
