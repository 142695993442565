import { client } from "@api/client";
import { insightOption, queryKeyForInsight } from "@api/useInsight";
import { supabase } from "@hooks/use-client";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import { SendInsightChatMessage } from "@mm/shared/endpoints/companion/insights.routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";

export const usePostMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      insightId,
      message,
    }: {
      insightId: number;
      message: string;
    }) => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.POST(SendInsightChatMessage.path, {
        params: {
          ...authHeaders,
          path: {
            insightId: String(insightId),
          },
        },
        body: {
          userMessage: message,
        },
      });

      if (error) {
        console.error(
          `Failed to add message to insight ${insightId}: ${error.error}`,
        );
        throw new Error(`Failed to add message to insight ${insightId}`);
      }

      return SendInsightChatMessage.responses[200].content[
        "application/json"
      ].schema.parse(data);
    },
    onMutate: async ({ insightId, message }) => {
      // Cancel any outgoing refetches
      const queryKey = queryKeyForInsight(insightId);
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const cacheData = queryClient.getQueryData(
        insightOption(insightId).queryKey,
      );

      if (!cacheData) {
        return { previousResponse: undefined };
      }

      const previousResponse = cacheData;
      //
      // Optimistically update to the new value
      const now = new Date().toISOString();
      queryClient.setQueryData(insightOption(insightId).queryKey, {
        status: previousResponse.status,
        insight: {
          ...previousResponse.insight,
          messages: [
            ...previousResponse.insight.messages,
            // Optimistically add user and waiting messages matching the API response structure
            // This specific structure is required for the messages to pass the filter in Messages.tsx:
            {
              display_role: "user",
              id: -2, // temporary id for user message
              created_at: now,
              gold_view_id: insightId,
              artifacts: [],
              message: {
                role: "user",
                content: [
                  {
                    text: message,
                    type: "text",
                  },
                ],
              } satisfies ChatCompletionMessageParam,
            },
            {
              display_role: "waiting",
              id: -1, // temporary id for waiting message
              created_at: new Date().toISOString(),
              gold_view_id: insightId,
              artifacts: [],
              message: {
                role: "assistant",
                content: [
                  {
                    text: "",
                    type: "text",
                  },
                ],
              } satisfies ChatCompletionMessageParam,
            },
          ],
        },
      });

      // Return a context object with the snapshotted value
      return { previousResponse };
    },
    onError: (_err, { insightId }, context) => {
      if (context?.previousResponse) {
        queryClient.setQueryData(
          queryKeyForInsight(insightId),
          context.previousResponse,
        );
      }
    },
    onSettled: async (_data, _err, { insightId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: queryKeyForInsight(insightId),
        }),
      ]);
    },
  });
};
