import {
  TypographyStylesProvider,
  type TypographyStylesProviderProps,
} from "@mantine/core";
import ReactMarkdown from "react-markdown";

type MessageContentProps = {
  children: string;
} & TypographyStylesProviderProps;

export const MessageContent = ({ children, ...props }: MessageContentProps) => (
  <TypographyStylesProvider
    flex={1}
    lh="lg"
    fz="sm"
    style={{
      wordBreak: "normal",
      overflowWrap: "anywhere",
    }}
    {...props}
  >
    <ReactMarkdown>{children}</ReactMarkdown>
  </TypographyStylesProvider>
);
