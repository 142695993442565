import { MessageContent } from "@components/Chat/Messages/MessageContent";
import { Box, Card, Stack, Text } from "@mantine/core";
import { LLMInsightResponse } from "@mm/shared/companion/types";

export const AssistantMessage = ({ content }: { content: string }) => {
  const { text: message } = LLMInsightResponse.parse(JSON.parse(content));

  return (
    <Stack style={{ overflow: "hidden" }} gap={0}>
      <Box
        bg={"gray.8"}
        px={"md"}
        py={2}
        style={{
          alignSelf: "flex-start",
          borderTopLeftRadius: "var(--mantine-radius-sm)",
          borderTopRightRadius: "var(--mantine-radius-sm)",
        }}
      >
        <Text size="sm" c={"white"}>
          Assistant
        </Text>
      </Box>
      <Card
        miw={150}
        shadow="none"
        bg={"gray.0"}
        style={{
          overflow: "hidden",
          border: "2px solid var(--mantine-color-gray-8)",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "var(--mantine-radius-md)",
          borderBottomRightRadius: "var(--mantine-radius-md)",
          borderBottomLeftRadius: "var(--mantine-radius-md)",
        }}
      >
        <MessageContent>{message}</MessageContent>
      </Card>
    </Stack>
  );
};
