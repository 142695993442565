import { useFlowData } from "@api/useFlow";
import { BarChart, BarDataFormatParser } from "@components/Charts/BarChart";
import {
  DoughnutChart,
  DoughnutDataFormatParser,
} from "@components/Charts/DoughnutChart";
import { LineChart, LineDataFormatParser } from "@components/Charts/LineChart";
import { Carousel } from "@mantine/carousel";
import { Center, Loader, Stack, Text } from "@mantine/core";
import { applyTransformations } from "@mm/shared/data/transform";
import {
  VisualizationConfigGenerationSchema,
  type FlowStep,
  type VisualizationConfigGeneration,
} from "@mm/shared/schemas/flows";
import { safeParseArray } from "@mm/shared/schemas/utils";
import { from } from "arquero";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useMemo } from "react";
import classes from "./Carousel.module.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  LineElement,
  PointElement,
  ArcElement,
);

const ShowGraph = ({
  config,
  data,
}: {
  config: VisualizationConfigGeneration;
  data: Record<string, unknown>[];
}) => {
  const transformations = config.transformation;

  const transformed = useMemo(() => {
    try {
      return applyTransformations(from(data), transformations).objects();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [data, transformations]);

  switch (config.plot_configuration.type) {
    case "doughnut": {
      const data = DoughnutDataFormatParser.array().safeParse(transformed);
      if (!data.data) return null;

      return (
        <Carousel.Slide>
          <Center>
            <DoughnutChart
              label={config.plot_configuration.label}
              data={data.data}
            />
          </Center>
        </Carousel.Slide>
      );
    }
    case "bar": {
      const data = BarDataFormatParser.array().safeParse(transformed);
      if (!data.data) return null;

      return (
        <Carousel.Slide>
          <BarChart label={config.plot_configuration.label} data={data.data} />
        </Carousel.Slide>
      );
    }
    case "line": {
      const data = LineDataFormatParser.array().safeParse(transformed);
      if (!data.data) return null;

      return (
        <Carousel.Slide>
          <LineChart label={config.plot_configuration.label} data={data.data} />
        </Carousel.Slide>
      );
    }
    default:
      return null;
  }
};

export const ViewGraphsCompleted = ({ step }: { step: FlowStep }) => {
  const graphConfigs = safeParseArray(
    step.output,
    VisualizationConfigGenerationSchema,
  );

  const { data: flowData } = useFlowData(step.flow_id, {
    filters: {},
    sorting: [],
  });

  if (graphConfigs.length == 0) {
    return (
      <Stack>
        <Text>No visualizations are available</Text>
      </Stack>
    );
  }

  if (!flowData) {
    return <Loader />;
  }

  const graphs = graphConfigs.map((config, i) => {
    return <ShowGraph config={config} data={flowData} key={i} />;
  });

  return (
    <Stack>
      <Carousel classNames={classes} withIndicators>
        {graphs}
      </Carousel>
    </Stack>
  );
};

export const ViewGraphsAwaitingInput = null;

export const ViewGraphsRunning = () => {
  return <Text>We're putting insights into charts...</Text>;
};
