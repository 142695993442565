import { useDebounce } from "@hooks/useDebounce";
import {
  Group,
  Input,
  Table as MantineTable,
  Stack,
  Text,
  UnstyledButton,
  type TableTheadProps,
} from "@mantine/core";
import { flexRender, type Table } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { LuChevronDown, LuChevronsUpDown, LuChevronUp } from "react-icons/lu";
import type { TableFilters } from ".";

type Props<T> = {
  table: Table<Record<PropertyKey, T>>;
  filters: TableFilters;
  handleFiltersChange: (filters: TableFilters) => void;
} & TableTheadProps;

export const TableHeader = <T,>({
  table,
  handleFiltersChange,
  filters,
  ...rest
}: Props<T>) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const setFilter = (column: string, value: string) => {
    if (value.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[column];
      handleFiltersChange(newFilters);
    } else {
      handleFiltersChange({
        ...filters,
        [column]: value,
      });
    }
  };

  const debouncedSetFilter = useDebounce(setFilter, 300);
  const handleInputChange = (column: string, value: string) => {
    setLocalFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
    debouncedSetFilter(column, value);
  };

  // Update local filters when props change
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <MantineTable.Thead {...rest}>
      {table.getHeaderGroups().map((headerGroup) => (
        <MantineTable.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const isSortable = header.column.getCanSort();
            const sortDirection = header.column.getIsSorted();
            const columnId = header.column.id;

            // Get the appropriate sort icon
            const SortIcon = (() => {
              if (sortDirection == "asc") return LuChevronUp;
              else if (sortDirection == "desc") return LuChevronDown;
              return LuChevronsUpDown;
            })();

            const value = header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext());

            return (
              <MantineTable.Th key={header.id} py={"sm"} px={"md"}>
                <Stack gap={"sm"}>
                  <UnstyledButton
                    onClick={header.column.getToggleSortingHandler()}
                    disabled={!isSortable}
                  >
                    <Group gap={"xs"} wrap="nowrap">
                      <Text span ff={"monospace"}>
                        {value}
                      </Text>
                      {isSortable && <SortIcon aria-hidden="true" />}
                    </Group>
                  </UnstyledButton>
                  <Input
                    maw={225}
                    fw={"normal"}
                    variant="filled"
                    size="xs"
                    placeholder={`Search by ${header.id}`}
                    value={localFilters[columnId] || ""}
                    onChange={(e) =>
                      handleInputChange(columnId, e.currentTarget.value)
                    }
                  />
                </Stack>
              </MantineTable.Th>
            );
          })}
        </MantineTable.Tr>
      ))}
    </MantineTable.Thead>
  );
};
