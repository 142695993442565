import { useInsight } from "@api/useInsight";
import { Page } from "@layout/Page";
import { Group, Stack, Text } from "@mantine/core";
import type { InsightDb } from "@mm/shared/schemas/insights";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Insight } from "@components/InsightBuilder/Insight";
import { useHotkeys } from "@mantine/hooks";
import { Onboarding } from "../components/InsightBuilder/Onboarding";
import { Steps } from "./Steps";

const InsightBuilderContent: React.FC<{
  insight: InsightDb;
}> = ({ insight }) => {
  const [chatOpened, setChatOpened] = useState(false);
  useHotkeys([["mod+K", () => setChatOpened((opened) => !opened)]]);

  return (
    <Page
      fullWidth
      title={
        <Text flex={1} size="xl" fw={"bold"} truncate="end">
          {insight.report?.name}
        </Text>
      }
      rightSection={
        <Steps active={3} reportLink={`/report/${insight.report?.id}`} />
      }
    >
      <Stack flex={1} style={{ overflow: "hidden" }} gap={"xs"}>
        <Group
          pr={"lg"}
          flex={1}
          align="stretch"
          wrap="nowrap"
          style={{ scrollBehavior: "smooth", overflow: "hidden" }}
        >
          {insight.onboarded ? (
            <Insight
              chatOpened={chatOpened}
              setChatOpened={setChatOpened}
              insight={insight}
            />
          ) : (
            <Onboarding insight={insight} />
          )}
        </Group>

        {insight.onboarded && (
          <Text p={"sm"} size="xs">
            {chatOpened ? "Hide " : "Open companion "}
            <Text span c={"dimmed"}>
              ⌘ K
            </Text>
          </Text>
        )}
      </Stack>
    </Page>
  );
};

export const InsightBuilder = () => {
  const params = useParams<{ insightId: string }>();
  const insightId = Number(params.insightId);

  const { data: insightResponse } = useInsight({ insightId });

  if (!insightResponse) return <Page fullWidth>{null}</Page>;

  return <InsightBuilderContent insight={insightResponse.insight} />;
};
