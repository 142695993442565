import { Grid, Stack, Text } from "@mantine/core";

import { StatsGrid } from "@components/FakeHome/StatsGrid";
import { StatsSegments } from "@components/FakeHome/StatsSegments";
import { UsersRolesTable } from "@components/FakeHome/UserRolesTable";
import { Page } from "@layout/Page";

export const FakeHome = () => {
  return (
    <Page>
      <Stack flex={1} p={"lg"}>
        <Text fz={"xl"} fw={600}>
          Good Morning, James!{" "}
          <Text span fz={"lg"}>
            Here's what's happening today.
          </Text>
        </Text>
        <Grid>
          <Grid.Col span={6}>
            <StatsGrid />
          </Grid.Col>
          <Grid.Col span={6}>
            <StatsSegments />
          </Grid.Col>
          <Grid.Col span={12}>
            <UsersRolesTable />
          </Grid.Col>
        </Grid>
      </Stack>
    </Page>
  );
};
