import { supabase } from "@hooks/use-client";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import {
  GetInsight,
  GetSuggestionsForInsight,
  ListInsights,
  RestartInsight,
  RestartInsightAtArtifact,
} from "@mm/shared/endpoints/companion/insights.routes";
import type { ArtifactDb, InsightDb } from "@mm/shared/schemas/insights";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { client } from "./client";

export const queryKeyForInsight = (insightId: number) => {
  return ["insights", { insightId }];
};

/*
 * This can seem superficial but is actually really useful when
 * dealing with queryClient.{set,get}QueryData with typescript.
 *
 * Because we are typing the queryFn properly here, the return
 * type of the {set,get}QueryData will be properly typed as well
 * @param insightId
 */
export const insightOption = (insightId: number) => {
  return queryOptions({
    queryKey: queryKeyForInsight(insightId),
    queryFn: async () => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.GET(GetInsight.path, {
        params: {
          ...authHeaders,
          path: {
            insightId: String(insightId),
          },
        },
      });

      if (error) {
        throw new Error(
          `Unable to retrieve insight with id: ${insightId}: ${error}`,
        );
      }

      return GetInsight.responses[200].content["application/json"].schema.parse(
        data,
      );
    },
    refetchInterval: ({ state: { data } }) => {
      // refetch the query every second if the status is pending
      if (!data || data.status === "pending") return 1000;
      return false;
    },
  });
};

export const useInsight = ({ insightId }: { insightId: number }) => {
  return useQuery(insightOption(insightId));
};

export const useInsightRestart = () => {
  return useMutation({
    mutationFn: async ({
      insight,
      artifact,
    }: {
      insight: InsightDb;
      artifact?: ArtifactDb;
    }) => {
      const authHeaders = await withAuthTokens(supabase);
      if (artifact) {
        const { data, error } = await client.POST(
          RestartInsightAtArtifact.path,
          {
            params: {
              ...authHeaders,
              path: {
                insightId: String(artifact.gold_view_id),
                artifactId: String(artifact.id),
              },
            },
          },
        );

        if (error) {
          console.error(
            `Unable to restart insight thread ${insight.id} at artifact ${artifact.id}: ${error.error}`,
          );
          throw new Error(`Unable to restart insight thread ${insight.id}`);
        }

        return RestartInsightAtArtifact.responses[200].content[
          "application/json"
        ].schema.parse(data);
      } else {
        const { data, error } = await client.POST(RestartInsight.path, {
          params: {
            ...authHeaders,
            path: {
              insightId: String(insight.id),
            },
          },
        });
        if (error) {
          console.error(
            `Unable to restart insight thread ${insight.id}: ${error.error}`,
          );
          throw new Error("Unable to restart insight thread");
        }

        return RestartInsight.responses[200].content[
          "application/json"
        ].schema.parse(data);
      }
    },
  });
};

export const useSuggestions = (insightId: number, messagesLength: number) => {
  return useQuery({
    queryKey: [
      ...queryKeyForInsight(insightId),
      "suggestions",
      { messagesLength },
    ],
    staleTime: Infinity,
    queryFn: async () => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.POST(GetSuggestionsForInsight.path, {
        params: {
          ...authHeaders,
          path: {
            insightId: String(insightId),
          },
        },
      });

      if (error) {
        console.error(
          `Unable to generate suggestions for insight: ${insightId}: ${error.error}`,
        );
        throw new Error(
          `Unable to generate suggestions for insight: ${insightId}`,
        );
      }

      return data;
    },
  });
};

export const useInsightsList = () => {
  return useQuery({
    queryKey: ["insights"],
    queryFn: async () => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.GET(ListInsights.path, {
        params: {
          ...authHeaders,
        },
      });

      if (error) {
        throw new Error(`Unable to retrieve list of insights: ${error}`);
      }

      return ListInsights.responses[200].content[
        "application/json"
      ].schema.parse(data);
    },
  });
};
