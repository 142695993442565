import { Badge, Box, Group, Text, Tooltip } from "@mantine/core";
import type { InsightDb } from "@mm/shared/schemas/insights";
import { NavLink } from "react-router-dom";
import { useArtifactIdParam } from "./Chat/ArtifactMessage";

import { useHover, useMergedRef, useMouse } from "@mantine/hooks";
import { useEffect } from "react";
import { BiFile, BiSolidFile } from "react-icons/bi";
import classes from "./ArtifactsList.module.css";

const SCROLL_CONSTANT = 15;
const SCROLL_DEAD_ZONE = 0.7;

export const ArtifactsList = ({ insight }: { insight: InsightDb }) => {
  const artifactId = useArtifactIdParam();

  const { ref: hoveredRef, hovered } = useHover();
  const { ref: mouseRef, x: mouseX, y: mouseY } = useMouse();
  const mergedRef = useMergedRef(hoveredRef, mouseRef);

  const artifacts = insight.messages
    .filter((message) => message.display_role === "artifact")
    .flatMap((message) => message.artifacts);

  useEffect(() => {
    const element = hoveredRef.current;
    let animationFrameId: number;

    if (!element) return;

    const scroll = () => {
      if (!hovered) return cancelAnimationFrame(animationFrameId);

      const rect = element.getBoundingClientRect();
      const centerX = rect.width / 2;

      // Calculate distance from center (normalized between -1 and 1)
      const distanceFromCenter = (mouseX - centerX) / (rect.width / 2);

      // Create a dead zone in the center (20% of width)
      if (Math.abs(distanceFromCenter) > SCROLL_DEAD_ZONE) {
        // Calculate scroll speed (pixels per frame)
        const scrollSpeed =
          SCROLL_CONSTANT *
          (Math.abs(distanceFromCenter) - SCROLL_DEAD_ZONE) *
          Math.sign(distanceFromCenter);

        element.scrollBy({ left: scrollSpeed, behavior: "auto" });
      }

      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrameId);
  }, [mouseX, mouseY, hoveredRef, mouseRef, hovered]);

  // Center the selected artifact when artifactId changes
  useEffect(() => {
    if (!hoveredRef.current) return;

    const dataSelector = artifactId ?? "report";
    const container = hoveredRef.current;
    const selectedElement = container.querySelector(
      `[data-breadcrumb-artifact="${dataSelector}"]`,
    );

    if (!selectedElement) return;

    // Calculate the center positions
    const containerRect = container.getBoundingClientRect();
    const elementRect = selectedElement.getBoundingClientRect();

    // Calculate the scroll position that would center the element
    const containerCenter = containerRect.width / 2;
    const elementCenter = elementRect.width / 2;
    const elementLeft =
      elementRect.left - containerRect.left + container.scrollLeft;

    const targetScroll = elementLeft - containerCenter + elementCenter;

    // Smooth scroll to the calculated position
    container.scrollTo({
      left: targetScroll,
      behavior: "smooth",
    });
  }, [artifactId, hoveredRef]);

  return (
    <Group wrap="nowrap" mb={"sm"}>
      <Text style={{ whiteSpace: "nowrap" }} c={"dimmed"} size="sm">
        All insights:
      </Text>
      <Box className={classes.root} flex={1}>
        <Group
          ref={mergedRef}
          style={{ flexWrap: "nowrap", overflow: "hidden" }}
          gap={"xs"}
        >
          <Tooltip label={insight.report?.name}>
            <Badge
              data-breadcrumb-artifact={"report"}
              maw={350}
              flex={"0 0 auto"}
              variant={"light"}
              color={
                !artifactId
                  ? "var(--mantine-primary-color-6)"
                  : "var(--mantine-color-dimmed)"
              }
              component={NavLink}
              replace
              to={`/insight/${insight.id}`}
              /* Not sure why but doesn't work with "style" */
              styles={{ root: { cursor: "pointer" } }}
            >
              {insight.report?.name}
            </Badge>
          </Tooltip>
          {artifacts.map((artifact) => (
            <Tooltip key={artifact.id} label={artifact.name}>
              <Badge
                data-breadcrumb-artifact={artifact.id}
                maw={350}
                flex={"0 0 auto"}
                variant={"light"}
                color={
                  artifactId === artifact.id
                    ? "var(--mantine-primary-color-6)"
                    : "var(--mantine-color-dimmed)"
                }
                component={NavLink}
                replace
                to={`/insight/${insight.id}?artifact=${artifact.id}`}
                /* Not sure why but doesn't work with "style" */
                styles={{ root: { cursor: "pointer" } }}
                leftSection={
                  artifactId === artifact.id ? <BiSolidFile /> : <BiFile />
                }
              >
                {artifact.name}
              </Badge>
            </Tooltip>
          ))}
        </Group>
      </Box>
    </Group>
  );
};
