import { Avatar, Group, Text, UnstyledButton } from "@mantine/core";
import { BiChevronRight } from "react-icons/bi";
import classes from "./UserButton.module.css";

export function UserButton() {
  return (
    <UnstyledButton className={classes.user}>
      <Group>
        <Avatar src="https://bit.ly/dan-abramov" radius="xl" />

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            James Ryan{" "}
            <Text span fw={400}>
              (Sony Music)
            </Text>
          </Text>

          <Text c="dimmed" size="xs">
            Digital Analytics Manager
          </Text>
        </div>

        <BiChevronRight size={14} />
      </Group>
    </UnstyledButton>
  );
}
