import { Paper, Text } from "@mantine/core";
import { Handle, Position } from "@xyflow/react";

export const TemplateNode = ({
  title,
  data: { explanation },
}: {
  title: string;
  data: { explanation: string };
}) => {
  return (
    <Paper
      w={300}
      mih={175}
      shadow="lg"
      p="md"
      m={12}
      ta={"left"}
      style={{ overflowWrap: "anywhere" }}
    >
      <Handle
        style={{ visibility: "hidden" }}
        type="target"
        position={Position.Left}
      />
      <Text fw={"bold"} mb={4}>
        {title}
      </Text>
      <Text lh={"xl"}>{explanation}</Text>
      <Handle
        style={{ visibility: "hidden" }}
        type="source"
        position={Position.Right}
      />
    </Paper>
  );
};
