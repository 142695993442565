import { Box, Card, Divider, Group, Stack, Text, Tooltip } from "@mantine/core";
import type { ArtifactDb } from "@mm/shared/schemas/insights";
import React from "react";
import { BiFile, BiSolidFile } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import classes from "./ArtifactMessage.module.css";

export const useArtifactIdParam = () => {
  const [searchParams] = useSearchParams();
  const artifact = searchParams.get("artifact");
  return artifact ? Number(artifact) : null;
};

const Artifact = ({ artifact }: { artifact: ArtifactDb }) => {
  const currentArtifactId = useArtifactIdParam();

  const [, setSearchParams] = useSearchParams();

  const openArtifact = () => {
    if (artifact.id === currentArtifactId) {
      setSearchParams(
        (params) => {
          params.delete("artifact");
          return params;
        },
        { replace: true },
      );
    } else {
      setSearchParams((params) => ({ ...params, artifact: artifact.id }), {
        replace: true,
      });
    }
  };

  return (
    <Group
      className={classes.artifact}
      onClick={() => openArtifact()}
      py={"sm"}
      px={"md"}
      data-message-artifact={artifact.id}
      gap="xs"
      justify="space-between"
      style={{ overflow: "hidden", cursor: "pointer" }}
    >
      <Tooltip label={artifact.name}>
        <Text
          size="sm"
          flex={1}
          truncate="end"
          c={
            artifact.id === currentArtifactId
              ? "var(--mantine-primary-color-6)"
              : undefined
          }
        >
          {artifact.name}
        </Text>
      </Tooltip>

      <Group gap={4}>
        {artifact.id === currentArtifactId ? (
          <BiSolidFile color="var(--mantine-primary-color-6)" />
        ) : (
          <BiFile color="var(--mantine-primary-color-6)" />
        )}

        <Text size="sm" c="var(--mantine-primary-color-6)">
          {artifact.id === currentArtifactId ? "close" : "open"}
        </Text>
      </Group>
    </Group>
  );
};

const ShowArtifacts = ({ artifacts }: { artifacts: ArtifactDb[] }) => {
  return (
    artifacts.length > 0 && (
      <Stack
        style={{
          overflow: "hidden",
        }}
        gap={0}
      >
        {artifacts.map((artifact, index) => (
          <React.Fragment key={artifact.id}>
            <Artifact artifact={artifact} />
            {artifacts.length > 1 && index !== artifacts.length - 1 ? (
              <Divider color="var(--mantine-primary-color-6)" size={"2px"} />
            ) : null}
          </React.Fragment>
        ))}
      </Stack>
    )
  );
};

export const ArtifactMessage = ({ artifacts }: { artifacts: ArtifactDb[] }) => {
  return (
    <Stack style={{ overflow: "hidden" }} gap={0}>
      <Box
        bg={"var(--mantine-primary-color-6)"}
        px={"md"}
        py={2}
        style={{
          alignSelf: "flex-start",
          borderTopLeftRadius: "var(--mantine-radius-sm)",
          borderTopRightRadius: "var(--mantine-radius-sm)",
        }}
      >
        <Text size="sm" c={"white"}>
          Your analysis is ready
        </Text>
      </Box>
      <Card
        p={0}
        shadow="none"
        bg={"gray.0"}
        style={{
          overflow: "hidden",
          border: "2px solid var(--mantine-primary-color-6)",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "var(--mantine-radius-md)",
          borderBottomRightRadius: "var(--mantine-radius-md)",
          borderBottomLeftRadius: "var(--mantine-radius-md)",
        }}
      >
        <ShowArtifacts artifacts={artifacts} />
      </Card>
    </Stack>
  );
};
