import {
  ReportBuilderProvider,
  useReportBuilder,
} from "@components/ReportBuilder/ReportBuilderContext";
import { Table } from "@components/ReportBuilder/Table";
import { ReportOnboarding } from "@components/ReportOnboarding";
import { Page } from "@layout/Page";
import { Text } from "@mantine/core";
import type { Report } from "@mm/shared/schemas/reports";
import React from "react";
import { Steps } from "./Steps";

export const ReportBuilder: React.FC = () => {
  return (
    <ReportBuilderProvider>
      <ReportBuilderContentWrapper />
    </ReportBuilderProvider>
  );
};

const ReportBuilderContent: React.FC<{ report: Report }> = ({ report }) => {
  if (!report.report.onboarded) {
    return <ReportOnboarding report={report} />;
  }

  return (
    <Page
      fullWidth
      title={
        <Text flex={1} size="xl" fw={"bold"} truncate="end">
          {report.report.name}
        </Text>
      }
      rightSection={<Steps active={2} />}
    >
      <Table report={report} />
    </Page>
  );
};

const ReportBuilderContentWrapper = () => {
  const { report } = useReportBuilder();

  if (!report) {
    return <Page fullWidth>{null}</Page>;
  }

  return <ReportBuilderContent report={report} />;
};
