import {
  ActionIcon,
  Box,
  Group,
  Image,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  Transition,
  type StackProps,
} from "@mantine/core";
import React, { type ComponentPropsWithRef } from "react";
import { BiInfoCircle, BiTrash } from "react-icons/bi";
import classes from "./Columns.module.css";

import TransformedColumnImage from "@images/transformed-column.svg";
import { useHover } from "@mantine/hooks";
import type { DetailedReportColumn } from "@mm/shared/schemas/reports";
import { useReportBuilder } from "./ReportBuilderContext";

const getConsistentRandom = (row: number, col: number, min = 40, max = 50) => {
  // Create a seed based on row and column
  const seed = row * 1000 + col;

  // Simple hash function
  const hash = Math.sin(seed) * 10000;

  // Get the decimal part
  const decimal = hash - Math.floor(hash);

  // Scale to your range
  return Math.floor(decimal * (max - min + 1)) + min;
};

type WrapperColumnProps = StackProps & ComponentPropsWithRef<"div">;

export const WrapperColumn: React.FC<WrapperColumnProps> = ({
  children,
  ref,
  ...props
}) => {
  return (
    <Stack ref={ref} {...props} className={props.className}>
      {children}
    </Stack>
  );
};

interface ColumnProps {
  column: DetailedReportColumn;
}

export const Column: React.FC<ColumnProps> = ({ column }) => {
  const { postMessage, isPosting } = useReportBuilder();
  const { hovered, ref } = useHover();

  return (
    <WrapperColumn ref={ref} w={"12rem"}>
      {/* Header */}
      <Group flex={0} gap={"xs"}>
        <Tooltip
          multiline
          maw={"25rem"}
          label={
            column.type === "transformation"
              ? "Transformed or calculated column. Data samples not available at this stage."
              : column.explanation
          }
        >
          <ActionIcon size="xs" color="blue" variant="transparent">
            <BiInfoCircle />
          </ActionIcon>
        </Tooltip>
        <Tooltip multiline maw={"25rem"} label={column.name}>
          <Text flex={1} truncate="end" fw={500}>
            {column.name}
          </Text>
        </Tooltip>
      </Group>

      {/* Data samples */}
      <Stack
        flex={1}
        pos={"relative"}
        style={{ overflow: "hidden" }}
        gap={"sm"}
        className={classes.fadeStack}
      >
        {column.type === "transformation" ? (
          <Stack
            pos={"absolute"}
            flex={1}
            ta={"center"}
            bg={
              "linear-gradient(180deg,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 10%, rgba(255,255,255,0.9) 90%, rgba(255,255,255,0) 100%"
            }
            style={{ zIndex: 2 }}
            py={75}
          >
            <Image mx={"auto"} w={"50%"} src={TransformedColumnImage} />
            <Text lh={"xl"} size={"sm"} c={"dimmed"}>
              {column.explanation}
            </Text>
          </Stack>
        ) : null}
        {column.type === "transformation"
          ? [...Array(50).keys()].map((idx) => (
              <Box key={idx}>
                <Skeleton
                  animate={false}
                  height={8}
                  width={`${getConsistentRandom(idx, column.id)}%`}
                  radius="xl"
                />
              </Box>
            ))
          : Array.isArray(column.data_sample) &&
            column.data_sample.map((value, index) => {
              return (
                <Box key={index}>
                  {value === null || value === "" ? (
                    <Text size="sm" ff={"monospace"} c="dimmed">
                      empty
                    </Text>
                  ) : (
                    <Tooltip
                      multiline
                      maw={"25rem"}
                      label={
                        <Text size="sm" ff={"monospace"}>
                          {String(value)}
                        </Text>
                      }
                    >
                      <Text size="sm" ff={"monospace"} truncate="end">
                        {String(value)}
                      </Text>
                    </Tooltip>
                  )}
                </Box>
              );
            })}
      </Stack>

      {/* Action buttons */}

      <Transition
        keepMounted
        mounted={hovered}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <ActionIcon.Group
            flex={0}
            mx={"auto"}
            style={{
              ...styles,
            }}
          >
            <Tooltip label="Remove column">
              <ActionIcon
                disabled={isPosting}
                variant="subtle"
                color="red"
                onClick={() => postMessage(`Remove "${column.name}" column`)}
              >
                <BiTrash />
              </ActionIcon>
            </Tooltip>
          </ActionIcon.Group>
        )}
      </Transition>
    </WrapperColumn>
  );
};
