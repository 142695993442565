import { createRoute, z } from "@hono/zod-openapi";
import { LLMVerifyResultsResponse } from "@mm/shared/companion/types";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import { ArtifactTableDataSchema } from "@mm/shared/schemas/artifact";
import type { ArtifactDb } from "@mm/shared/schemas/insights";
import {
  cursorParams,
  filterQueryParams,
  limitQueryParams,
  orderQueryParams,
} from "../utils";

const ArtifactIdParam = z
  .object({ artifactId: z.string().pipe(z.coerce.number()) })
  .openapi("ArtifactId", { type: "integer" });

export const GetArtifact = createRoute({
  method: "get",
  path: "/api/v1/artifacts/{artifactId}",
  operationId: "retrieveArtifact",
  request: {
    headers: SupabaseHeaderSchema,
    params: ArtifactIdParam,
  },
  responses: {
    200: {
      description: "Retrieve an artifact",
      content: {
        "application/json": {
          schema: z.custom<ArtifactDb>(),
        },
      },
    },
    404: {
      description: "Artifact does not exists",
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
    },
  },
});

export const ExportArtifact = createRoute({
  method: "post",
  path: "/api/v1/artifacts/{artifactId}/export",
  operationId: "exportArtifact",
  request: {
    headers: SupabaseHeaderSchema,
    params: ArtifactIdParam,
    query: z.object({
      limit: limitQueryParams,
    }),
  },
  responses: {
    200: {
      description: "Download an artifact",
      content: {
        "text/csv": {
          schema: z.string(),
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
    },
  },
});

export const GetArtifactData = createRoute({
  method: "get",
  path: "/api/v1/artifacts/{artifactId}/data",
  operationId: "getArtifactData",
  request: {
    headers: SupabaseHeaderSchema,
    params: ArtifactIdParam,
    query: z.object({
      limit: limitQueryParams,
      order: orderQueryParams,
      cursor: cursorParams,
      filter: filterQueryParams,
    }),
  },
  responses: {
    200: {
      description: "Retrieve data from an artifact",
      content: {
        "application/json": {
          schema: ArtifactTableDataSchema,
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
    },
  },
});

export const VerifyArtifact = createRoute({
  method: "get",
  operationId: "verifyArtifact",
  path: "/api/v1/artifacts/{artifactId}/verify",
  request: {
    headers: SupabaseHeaderSchema,
    params: ArtifactIdParam,
  },
  responses: {
    200: {
      description: "Verify an artifact",
      content: {
        "application/json": {
          schema: LLMVerifyResultsResponse,
        },
      },
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
    },
  },
});
