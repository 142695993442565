import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";

export const End2EndApiResponseSchema = z.discriminatedUnion("status", [
  z.object({
    status: z.literal("error"),
    error: z.string(),
  }),
  z.object({
    status: z.literal("success"),
    flowId: z.string(),
  }),
]);

export type End2EndApiResponse = z.infer<typeof End2EndApiResponseSchema>;

export const RunEnd2EndFlowRoute = createRoute({
  method: "post",
  path: "/api/v1/end2end",
  operationId: "runEnd2EndFlow",
  request: {
    headers: SupabaseHeaderSchema,
    body: {
      required: true,
      content: {
        "application/json": {
          schema: z.object({ userQuery: z.string() }),
        },
      },
    },
  },
  responses: {
    200: {
      description: "Run the end2end flow",
      content: {
        "application/json": {
          schema: End2EndApiResponseSchema,
        },
      },
    },
  },
});
