import {
  Button,
  Card,
  CardSection,
  Center,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import type { DetailedReportColumn } from "@mm/shared/schemas/reports";
import { BiAddToQueue } from "react-icons/bi";

const formatValue = (value: unknown) => {
  if (value === undefined || value === null || value === "") {
    return (
      <Text span c={"dimmed"}>
        empty
      </Text>
    );
  }
  return String(value);
};

const formatArray = (values: unknown[]) => {
  if (!values.length) {
    return (
      <Text span c="dimmed">
        empty
      </Text>
    );
  }

  // Filter out empty/null/undefined values before creating the Set
  const uniqueValues = [
    ...new Set(
      values
        .filter((v) => v !== undefined && v !== null && v !== "")
        .map(String),
    ),
  ];

  // If all values were filtered out, show empty
  if (!uniqueValues.length) {
    return (
      <Text span c="dimmed">
        empty
      </Text>
    );
  }

  return (
    <>
      {uniqueValues.map((v, i) => (
        <Text key={i} span>
          {v}
          <br />
        </Text>
      ))}
    </>
  );
};

const LineValue = ({ value }: { value: unknown }) => {
  if (Array.isArray(value)) {
    return formatArray(value);
  }

  return formatValue(value);
};

const Line: React.FC<{
  label: string;
  value: unknown;
  truncate?: boolean;
  mono?: boolean;
  fontWeight?: number;
}> = ({ label, value, truncate = false, mono = false, fontWeight }) => (
  <Group align="flex-start" gap="lg">
    <Text
      size="sm"
      ta="right"
      flex="0 0 5rem"
      c="dimmed"
      tt="uppercase"
      style={{
        wordBreak: "normal",
        overflowWrap: "anywhere",
        ...(fontWeight ? { fontWeight } : {}),
      }}
    >
      {label}
    </Text>
    <Text
      size="sm"
      flex={1}
      style={{
        wordBreak: "normal",
        overflowWrap: "anywhere",
        ...(fontWeight ? { fontWeight } : {}),
      }}
      ff={mono ? "monospace" : "text"}
      truncate={truncate ? "end" : undefined}
    >
      <LineValue value={value} />
    </Text>
  </Group>
);

export const Column = ({
  column,
  handleNext,
}: {
  column: DetailedReportColumn;
  handleNext: () => void;
}) => {
  const formattedNullPercentage =
    column.null_percentage !== null
      ? `${Math.round(column.null_percentage * 100)}%`
      : `N/A`;

  return (
    <Card flex={1} shadow="md" withBorder>
      <CardSection bg={"gray.2"} px={"md"} py={"xs"}>
        <Stack gap={2}>
          <Line label={"Source"} value={column.source_name} />
          <Line label={"Table"} value={column.display_table_name} />
          <Line label={"Column"} value={column.display_name} fontWeight={600} />
        </Stack>
      </CardSection>
      <CardSection flex={1} px={"md"} py={"xs"} style={{ overflow: "hidden" }}>
        <Stack h={"100%"}>
          <Stack gap={"xs"} flex={1} style={{ overflow: "hidden" }}>
            <Line label={"Why"} value={column.explanation} />
            <Line mono label={"Unique values"} value={column.unique_values} />
            <Line
              mono
              label={"Missing values"}
              value={formattedNullPercentage}
            />
            <Line
              truncate
              mono
              label={"Data samples"}
              value={
                column.type === "raw"
                  ? Array.isArray(column.data_sample)
                    ? column.data_sample.map(String)
                    : null
                  : null
              }
            />
          </Stack>
          <Center>
            <Button
              size="xs"
              onClick={handleNext}
              rightSection={<BiAddToQueue size={14} />}
            >
              Add to data preparation
            </Button>
          </Center>
        </Stack>
      </CardSection>
    </Card>
  );
};
