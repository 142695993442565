import { Button, Center, Image, Stack, Text } from "@mantine/core";
import { BiRightArrowAlt } from "react-icons/bi";

import { useReportBuilder } from "@components/ReportBuilder/ReportBuilderContext";
import ReportOnboardingDoneImage from "@images/report-onboarding-done.svg";

export const Outro = () => {
  const { setIsOnboarded, isSettingOnboarded } = useReportBuilder();

  return (
    <Center h={"100%"} flex={1}>
      <Stack flex={1} align="center" justify="center" gap={"lg"}>
        <Stack gap={0} align="center">
          <Text size="xl" fw={"bold"}>
            Congrats, you're done 🎉
          </Text>
          <Text size="md">Your data is ready</Text>
        </Stack>
        <Image w={"40%"} src={ReportOnboardingDoneImage} />
        <Button
          loading={isSettingOnboarded}
          onClick={setIsOnboarded}
          rightSection={<BiRightArrowAlt />}
        >
          Go to data preparation
        </Button>
      </Stack>
    </Center>
  );
};
