import { useReportBuilder } from "@components/ReportBuilder/ReportBuilderContext";
import { Button, Stack } from "@mantine/core";
import type { Report } from "@mm/shared/schemas/reports";
import { AnimatedColumns } from "./AnimatedColumns";

export const Review = ({ report }: { report: Report }) => {
  const { setIsOnboarded, isSettingOnboarded } = useReportBuilder();

  return (
    <Stack pb={"sm"} flex={1} gap={"lg"}>
      <AnimatedColumns columns={report.columns} />
      <Button
        loading={isSettingOnboarded}
        onClick={setIsOnboarded}
        size="xs"
        variant="transparent"
      >
        Skip to data preparation
      </Button>
    </Stack>
  );
};
