import type { TableFilters } from "@components/InsightBuilder/Table";
import type { SortingState } from "@tanstack/react-table";

export const toSearchParams = ({
  cursor,
  filters,
  sorting,
  limit,
}: {
  cursor?: number;
  filters?: TableFilters;
  sorting?: SortingState;
  limit?: number;
}) => {
  const params: Record<string, string> = {};

  if (limit !== undefined) {
    params.limit = String(limit);
  }

  if (cursor !== undefined) {
    params.cursor = String(cursor);
  }

  const sortingParams = sorting
    ?.map((c) => `${c.id}:${c.desc ? "DESC" : "ASC"}`)
    .join(",");

  if (sortingParams) {
    params.order = sortingParams;
  }

  if (filters) {
    const filterParams = Object.entries(filters)
      .flatMap(([key, value]) => {
        if (
          !(
            typeof value === "string" ||
            typeof value === "boolean" ||
            typeof value === "number"
          )
        )
          return [];
        return `${key}:${encodeURIComponent(value)}`;
      })
      .join(",");

    if (filterParams.length > 0) {
      params.filter = filterParams;
    }
  }

  return params;
};
