import { Group, Paper, SimpleGrid, Text } from "@mantine/core";
import {
  BiChart,
  BiDollar,
  BiHeadphone,
  BiMicrophone,
  BiRightDownArrowCircle,
  BiRightTopArrowCircle,
} from "react-icons/bi";
import classes from "./StatsGrid.module.css";

const icons = {
  user: BiHeadphone,
  cart: BiMicrophone,
  receipt: BiDollar,
  coin: BiChart,
};

const data = [
  { title: "Ad Revenue", icon: "receipt", value: "13,456", diff: 34 },
  { title: "Ad Impressions", icon: "coin", value: "4.1M", diff: -13 },
  { title: "Active Artists", icon: "cart", value: "745", diff: 18 },
  { title: "Monthly Streams", icon: "user", value: "188K", diff: -30 },
] as const;

export function StatsGrid() {
  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    const DiffIcon =
      stat.diff > 0 ? BiRightTopArrowCircle : BiRightDownArrowCircle;

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size={22} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>{stat.value}</Text>
          <Text
            c={stat.diff > 0 ? "green" : "red"}
            fz="sm"
            fw={500}
            className={classes.diff}
          >
            <span>{stat.diff}%</span> <DiffIcon size={16} />
          </Text>
        </Group>

        <Text fz="xs" c="dimmed" mt={7}>
          Compared to previous month
        </Text>
      </Paper>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid cols={2}>{stats}</SimpleGrid>
    </div>
  );
}
