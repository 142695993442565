import { supabase } from "@hooks/use-client";
import {
  Button,
  Center,
  Group,
  Loader,
  Select,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import {
  compareCandidates,
  type Report,
  type ReportColumnCandidate,
} from "@mm/shared/schemas/reports";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { queryKeyForReport } from "../ReportBuilderContext";
import { JoinableTable } from "./JoinableTable";
import { useJoinableColumns } from "./useJoinableColumns";

import { client } from "@api/client";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import { AddColumnToReport } from "@mm/shared/endpoints/companion/reports.routes";
import classes from "./Tabs.module.css";

interface AddModalProps {
  report: Report;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const SourceSelection = ({
  data,
  selectedSource,
  onChange,
}: {
  data: string[];
  selectedSource: string | null;
  onChange: (selectedSource: string | null) => void;
}) => (
  <Group
    py={"xs"}
    px={"lg"}
    justify="space-between"
    style={{ borderBottom: "1px solid var(--mantine-color-gray-5)" }}
  >
    <Text fw={"bold"}>Add columns</Text>
    {selectedSource && (
      <Select
        clearable
        size="sm"
        variant="filled"
        data={data}
        value={selectedSource}
        onChange={onChange}
      />
    )}
  </Group>
);

// Define the mutation function
const TableSelection = ({
  joinableColumns,
  selectedSource,
  selectedTable,
  setSelectedTable,
  selectedColumns,
  setSelectedColumns,
  onClick,
}: {
  joinableColumns: Exclude<
    ReturnType<typeof useJoinableColumns>["data"],
    undefined
  >;
  selectedSource: string | null;
  selectedTable: string | null;
  setSelectedTable: React.Dispatch<React.SetStateAction<string | null>>;
  selectedColumns: ReportColumnCandidate[];
  setSelectedColumns: React.Dispatch<
    React.SetStateAction<ReportColumnCandidate[]>
  >;
  onClick: (sourceName: string) => void;
}) => {
  const selectColumn = (column: ReportColumnCandidate) => {
    setSelectedColumns((columns) => {
      if (columns.find((c) => compareCandidates(c, column))) {
        return columns;
      }

      return [...columns, column];
    });
  };

  const deselectColumn = (column: ReportColumnCandidate) => {
    setSelectedColumns((columns) => {
      return columns.filter((c) => !compareCandidates(c, column));
    });
  };

  if (!selectedSource) {
    return (
      <Stack flex={1} px="sm">
        <Text fw={600}>Select your source:</Text>
        {Object.keys(joinableColumns).map((sourceName) => (
          <Button key={sourceName} onClick={() => onClick(sourceName)}>
            {sourceName}
          </Button>
        ))}
      </Stack>
    );
  }

  const availableTables = Object.keys(joinableColumns[selectedSource] ?? {});

  return (
    <Stack flex={1} style={{ overflow: "hidden" }} py={"sm"} px={"lg"}>
      <Tabs
        classNames={classes}
        variant="pills"
        value={selectedTable}
        onChange={setSelectedTable}
      >
        <Tabs.List>
          {availableTables.map((displayTableName) => (
            <Tabs.Tab key={displayTableName} value={displayTableName}>
              {displayTableName}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      {selectedTable ? (
        <JoinableTable
          selectColumn={selectColumn}
          deselectColumn={deselectColumn}
          displayTableName={selectedTable}
          selectedColumns={selectedColumns}
          columnCandidates={
            joinableColumns[selectedSource]?.[selectedTable] ?? []
          }
        />
      ) : (
        <Text c={"dimmed"}>Select a table</Text>
      )}
    </Stack>
  );
};

// Create the mutation hook
const useAddColumnsToReport = ({
  report,
  closeDialog,
}: {
  report: Report;
  closeDialog?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (columns: ReportColumnCandidate[]) => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.POST(AddColumnToReport.path, {
        body: {
          columns: columns,
        },
        params: {
          ...authHeaders,
          path: {
            reportId: String(report.report.id),
          },
        },
      });
      if (error) {
        throw new Error(`Failed to start report: ${error}`);
      }

      return data;
    },
    onSuccess: () => {
      if (closeDialog) {
        closeDialog();
      }

      const queryKey = queryKeyForReport(report.report.id);
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const AddModal: React.FC<AddModalProps> = ({ setOpened, report }) => {
  const { data: joinableColumns, isPending } = useJoinableColumns();

  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [selectedTable, setSelectedTable] = useState<string | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<
    ReportColumnCandidate[]
  >([]);
  const thisRef = useRef<HTMLDivElement>(null);

  const { mutate: addColumnsToReport, isPending: addingColumn } =
    useAddColumnsToReport({
      report,
      closeDialog: () => {
        setOpened(false);
      },
    });

  /*
   * Whenever this element is mounted, we want to make sure that the user is seeing it.
   * By capturing the ref and scrolling the div into the view we make sure this is the case
   */
  useEffect(() => {
    if (!isPending) {
      if (thisRef.current) thisRef.current.scrollIntoView();
    }
  }, [isPending, thisRef]);

  if (isPending) {
    return (
      <Center flex={1} w={"30rem"}>
        <Loader />
      </Center>
    );
  }

  if (!joinableColumns || Object.keys(joinableColumns).length === 0) {
    return <Text c="dimmed">No additional columns available.</Text>;
  }

  return (
    <Stack ref={thisRef} flex={1} w={"30rem"} justify="stretch" h={"100%"}>
      <SourceSelection
        data={Object.keys(joinableColumns)}
        selectedSource={selectedSource}
        onChange={(val) => {
          setSelectedSource(val);
          setSelectedTable(null);
        }}
      />

      <TableSelection
        joinableColumns={joinableColumns}
        selectedSource={selectedSource}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onClick={(sourceName) => {
          setSelectedSource(sourceName);
          setSelectedTable(null);
        }}
      />

      <Group py={"xs"} px={"lg"} justify="space-between" flex={0}>
        <Button size="sm" variant="subtle" onClick={() => setOpened(false)}>
          Close
        </Button>
        <Button
          disabled={selectedColumns.length === 0}
          size="sm"
          loading={addingColumn}
          variant="light"
          onClick={() => addColumnsToReport(selectedColumns)}
        >
          Add {selectedColumns.length}{" "}
          {selectedColumns.length > 1 ? "columns" : "column"}
        </Button>
      </Group>
    </Stack>
  );
};
