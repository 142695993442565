import { useFlowColumns, useFlowData } from "@api/useFlow";
import type { TableFilters } from "@components/InsightBuilder/Table";
import {
  Button,
  Center,
  Group,
  Image,
  Loader,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import type { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Table } from "./Table";

import EmptyTablesImage from "@images/empty-tables.svg";
import { PiChatCircleDuotone, PiQuestionDuotone } from "react-icons/pi";
import type { FlowStep } from "@mm/shared/schemas/flows";

const EmptyTable = () => (
  <Stack style={{ textAlign: "center" }} gap={"sm"} my={"md"}>
    <Image m={"auto"} w={100} alt="Empty messages" src={EmptyTablesImage} />
    <Text size="sm" c={"dimmed"}>
      This resulting data is empty
    </Text>
  </Stack>
);

export const ExecuteProcedureCompleted = ({ step }: { step: FlowStep }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<TableFilters>({});
  const { data: dataColumns, isPending: isPendingColumns } = useFlowColumns(
    step.flow_id,
  );
  const { data, isPending: isPendingData } = useFlowData(step.flow_id, {
    sorting,
    filters,
  });

  if (isPendingColumns || isPendingData) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack gap={"sm"}>
      <Group justify="space-between">
        <Text>Your data is ready:</Text>
        <Button
          fw={"normal"}
          leftSection={
            <PiQuestionDuotone size={14} color="var(--mantine-color-dimmed)" />
          }
          size="compact-xs"
          color="var(--mantine-color-dimmed)"
          variant="transparent"
        >
          How was this data prepared?
        </Button>
      </Group>
      <Table
        data={data}
        dataColumns={dataColumns}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
        sorting={sorting}
        filters={filters}
        emptyTable={<EmptyTable />}
      />
      <Space h={"lg"} />
      <Stack gap={2}>
        <Text size="sm" c="dimmed">
          Give feedback to modify results:
        </Text>
        <Group>
          <TextInput
            flex={1}
            leftSection={
              <PiChatCircleDuotone
                color={"var(--mantine-primary-color-6)"}
                size={18}
              />
            }
            size="sm"
            placeholder="What is different than expected?"
          />
          <Button variant="light" size="sm">
            Give feedback
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
};

export const ExecuteProcedureAwaitingInput = null;

export const ExecuteProcedureRunning = () => {
  return <Text>We're connecting all the dots...</Text>;
};
