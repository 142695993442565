import { Notifications } from "@components/Settings/Notifications";
import { Page } from "@layout/Page";

export const Settings = () => {
  return (
    <Page title="Settings">
      <Notifications />
    </Page>
  );
};
