import {
  Alert,
  Box,
  List,
  Stack,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import type { LLMVerifyResultsResponseType } from "@mm/shared/companion/types";
import { BiInfoCircle } from "react-icons/bi";
import ReactMarkdown from "react-markdown";

const SECTION_DESCRIPTIONS = {
  Implementation:
    "Analysis of how the implementation matches the discussion and requirements.",
  Assumptions:
    "Critical decisions made during the implementation that affect how the data should be interpreted.",
} as const;

const ExplanationSection: React.FC<{
  title: keyof typeof SECTION_DESCRIPTIONS;
  content:
    | string
    | string[]
    | Array<{
        type: string;
        details: string;
        explanation: string;
      }>;
}> = ({ title, content }) => {
  return (
    <Box p={"sm"}>
      <Text fw={"bold"}>{title}</Text>
      <Text mb="sm" c={"dimmed"} size="sm">
        {SECTION_DESCRIPTIONS[title]}
      </Text>
      {Array.isArray(content) ? (
        content.length > 0 ? (
          typeof content[0] === "string" ? (
            <List spacing={"xs"}>
              {(content as string[]).map((item, index) => (
                <List.Item key={index}>
                  <TypographyStylesProvider fz="sm">
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </TypographyStylesProvider>
                </List.Item>
              ))}
            </List>
          ) : (
            <Stack gap="md">
              {(
                content as Array<{
                  type: string;
                  details: string;
                  explanation: string;
                }>
              ).map((item) => (
                <Stack key={`${item.type}_${item.details}`} gap="xs">
                  <Text size="sm" fw={500} tt="capitalize">
                    {item.type} assumption:
                  </Text>
                  <Text size="sm">Details: {item.details}</Text>
                  <Text size="sm">Explanation: {item.explanation}</Text>
                </Stack>
              ))}
            </Stack>
          )
        ) : (
          <Text size="sm">None.</Text>
        )
      ) : (
        <TypographyStylesProvider fz="sm">
          <ReactMarkdown>{content ? content : "None."}</ReactMarkdown>
        </TypographyStylesProvider>
      )}
    </Box>
  );
};

export const GeneralAnalysis = ({
  generalAnalysis,
}: {
  generalAnalysis: LLMVerifyResultsResponseType["generalAnalysis"];
}) => {
  return (
    <Stack>
      <Alert mx={"lg"} icon={<BiInfoCircle />} title="Summary">
        {generalAnalysis.summary}
      </Alert>
      <ExplanationSection
        title="Implementation"
        content={generalAnalysis.implementation}
      />
      <ExplanationSection
        title="Assumptions"
        content={generalAnalysis.assumptions}
      />
    </Stack>
  );
};
