import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import { FlowDataSchema, FlowColumnsSchema } from "@mm/shared/schemas/flows";
import {
  cursorParams,
  filterQueryParams,
  limitQueryParams,
  orderQueryParams,
} from "../utils";

export const FlowIdParam = z
  .object({ flowId: z.string().uuid() })
  .openapi("FlowId", { type: "string" });

export const RetrieveFlowColumns = createRoute({
  method: "get",
  path: "/api/v1/flows/{flowId}/columns",
  operationId: "retrieveFlowColumns",
  request: {
    headers: SupabaseHeaderSchema,
    params: FlowIdParam,
  },
  responses: {
    200: {
      description: "A list containing the columns of the flow",
      content: {
        "application/json": {
          schema: FlowColumnsSchema,
        },
      },
    },
    404: {
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
      description: "Unable to retrieve flow with this id",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});

export const RetrieveFlowData = createRoute({
  method: "get",
  path: "/api/v1/flows/{flowId}/data",
  operationId: "retrieveFlowData",
  request: {
    headers: SupabaseHeaderSchema,
    params: FlowIdParam,
    query: z.object({
      limit: limitQueryParams,
      order: orderQueryParams,
      cursor: cursorParams,
      filter: filterQueryParams,
    }),
  },
  responses: {
    200: {
      description: "The content of the flow",
      content: {
        "application/json": {
          schema: FlowDataSchema,
        },
      },
    },
    404: {
      content: {
        "application/json": {
          schema: z.object({ error: z.string() }),
        },
      },
      description: "Unable to retrieve flow with this id",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
