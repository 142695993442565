import { useFlow, useStartFlow } from "@api/useFlow";
import { Recommendations } from "@components/E2E/Recommendations";
import { StepTracker } from "@components/E2E/StepTracker";
import { Page } from "@layout/Page";
import { Button, Center, Group, Stack, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { PiMagicWandDuotone } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";

export const E2E = () => {
  const navigate = useNavigate();
  const { flowId } = useParams<{ flowId: string }>();
  const { mutate, isPending } = useStartFlow();
  const [requestInput, setRequestInput] = useState("");

  const { data: flowData } = useFlow(flowId);

  const flowSteps = flowData?.flow_steps ?? [];
  const request = flowData?.request ?? requestInput;

  const startFlow = (request: string) => {
    mutate(request.trim(), {
      onSuccess: (data) => {
        navigate(`/e2e/${data.flowId}`);
      },
    });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();

    if (!request.trim()) {
      return;
    }

    startFlow(request);
  };

  return (
    <Page>
      <Center flex={1}>
        <Stack h={"100%"} w={750} gap={"xl"} pb={"xl"}>
          <Group justify="space-between" gap={"md"} align="flex-end">
            <Stack gap={0}>
              <Text fw={"bold"} size="lg">
                Welcome!
              </Text>
              <Text size="lg">What insights are you looking for?</Text>
            </Stack>
            <form style={{ flex: "0 1 400px" }} onSubmit={handleSubmit}>
              <TextInput
                value={request}
                onChange={(e) => setRequestInput(e.target.value)}
                disabled={isPending || !!flowId}
                size="md"
                placeholder="Ask anything about your data..."
                rightSectionWidth={64}
                leftSection={
                  <PiMagicWandDuotone
                    color={
                      isPending || !!flowId
                        ? "gray"
                        : "var(--mantine-primary-color-6)"
                    }
                    size={18}
                  />
                }
                rightSection={
                  <Button
                    loading={isPending}
                    disabled={isPending || !!flowId}
                    radius="sm"
                    size="xs"
                    type="submit"
                  >
                    Ask
                  </Button>
                }
              />
            </form>
          </Group>
          {flowId ? (
            <StepTracker flowSteps={flowSteps} />
          ) : (
            <Recommendations
              startFlow={(request) => Promise.resolve(startFlow(request))}
            />
          )}
        </Stack>
      </Center>
    </Page>
  );
};
