import type { InsightDb } from "@mm/shared/schemas/insights";
import { Chat } from "./Chat";
import { DataPreview } from "./DataPreview";

export const Insight = ({
  insight,
  chatOpened,
  setChatOpened,
}: {
  insight: InsightDb;
  chatOpened: boolean | undefined;
  setChatOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Chat
        chatOpened={chatOpened}
        setChatOpened={setChatOpened}
        insightId={insight.id}
        messages={insight.messages}
      />
      <DataPreview insight={insight} />
    </>
  );
};
