import type { FormEvent } from "react";

import { useClient } from "@hooks/use-client";
import { NavLink as RouterNavLink, useNavigate } from "react-router-dom";

import {
  Badge,
  Button,
  Drawer,
  Group,
  Image,
  Loader,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";

import { BiCog, BiCollection, BiHome, BiTable } from "react-icons/bi";

import EmptyDataCatalogImage from "@images/empty-data-catalog.svg";
import EmptyReportsImage from "@images/empty-reports.svg";

import { useInsightsList } from "@api/useInsight";
import { FINAL } from "@mm/shared/schemas/gold";
import type { InsightList } from "@mm/shared/schemas/insights";
import dayjs from "dayjs";
import { useReportsList } from "../../api/useReportsList";
import { MenuItem } from "./MenuItem";

const InsightMenuItem = ({
  id,
  status,
  table_display_name,
  artifacts,
}: InsightList[number]) => {
  const lastArtifact = artifacts.at(-1);
  const numberOfArtifacts = artifacts.length;

  if (lastArtifact) {
    return (
      <MenuItem
        key={id}
        to={`/insight/${id}?artifact=${lastArtifact.id}`}
        label={
          <Group
            gap={"xs"}
            wrap="nowrap"
            style={{ justifyContent: "space-between" }}
          >
            <Tooltip fz={"sm"} label={lastArtifact.name}>
              <Text flex={1} size="sm" truncate="end">
                {lastArtifact.name}
              </Text>
            </Tooltip>
            {numberOfArtifacts > 1 && (
              <Badge flex={"0 0 auto"} variant="light" size="xs">
                {(() => {
                  if (numberOfArtifacts == 2) return "1 other";
                  else if (numberOfArtifacts < 10)
                    return `${numberOfArtifacts - 1} others`;
                  else return "9+ others";
                })()}
              </Badge>
            )}
          </Group>
        }
        description={`${table_display_name}`}
      />
    );
  }

  return (
    <MenuItem
      key={id}
      to={`/insight/${id}`}
      label={
        <Group
          gap={"xs"}
          wrap="nowrap"
          style={{ justifyContent: "space-between" }}
        >
          <Tooltip fz={"sm"} label={table_display_name}>
            <Text flex={1} size="sm" truncate="end">
              {table_display_name}
            </Text>
          </Tooltip>
          {status !== FINAL ? (
            <Badge flex={"0 0 auto"} variant="light" size="xs" color={"gray"}>
              error
            </Badge>
          ) : null}
        </Group>
      }
      description={`${table_display_name}`}
    />
  );
};

export const Menu = () => {
  const navigate = useNavigate();
  const { data: reports } = useReportsList();
  const { data: insightsList } = useInsightsList();
  const { supabase, user } = useClient();

  const signOut = async (e: FormEvent) => {
    e.preventDefault();
    await supabase.auth.signOut({ scope: "local" });
  };

  const renderMenuItems = <T extends Record<PropertyKey, unknown>>(
    items: T[] | null | undefined,
    viewAllLink: string,
    emptyState: {
      image: string;
      text: string;
    },
    renderItem: (item: T) => React.ReactNode,
  ) => {
    if (!items) {
      return <Loader />;
    }

    if (items.length === 0) {
      return (
        <Stack style={{ textAlign: "center" }} gap={8} mt={8} mb={8}>
          <Image
            m={"auto"}
            w={"25%"}
            alt="Empty state"
            src={emptyState.image}
          />
          <Text size={"xs"} c={"dimmed"}>
            {emptyState.text}
          </Text>
        </Stack>
      );
    }

    const truncatedItems = items.slice(0, 5);
    return (
      <>
        {truncatedItems.map(renderItem)}
        {items.length > 5 ? (
          <Button
            component={RouterNavLink}
            to={viewAllLink}
            size="xs"
            color="blue"
            variant="transparent"
            fullWidth
          >
            View all {items.length} items &hellip;
          </Button>
        ) : (
          <Button
            component={RouterNavLink}
            to={viewAllLink}
            size="xs"
            color="blue"
            variant="transparent"
            fullWidth
          >
            View details
          </Button>
        )}
      </>
    );
  };

  return (
    <Drawer.Root
      opened={true}
      onClose={() => navigate({ hash: "" })}
      padding={"lg"}
      shadow={"xl"}
      size={"sm"}
      /* 201 to be above Affix default of 200 */
      zIndex={201}
      styles={{
        content: { display: "flex", flexDirection: "column" },
        body: { flex: 1, display: "flex", flexDirection: "column" },
      }}
    >
      <Drawer.Overlay backgroundOpacity={0.1} blur={4} />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title fz={"lg"} fw={"bold"}>
            MageMetrics
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          <Stack flex={1} gap={0}>
            <MenuItem label={"Home"} icon={BiHome} to={"/"} />
            {/* Must set  to={"/report"} so we can automatically open parent NavLink when children NavLink is the active one */}
            <MenuItem label={"Data Preparations"} icon={BiTable} to={"/report"}>
              {renderMenuItems(
                reports,
                "/report",
                {
                  image: EmptyReportsImage,
                  text: "You haven't started any reports yet.",
                },
                ({ id, name, created_at }) => (
                  <MenuItem
                    key={id}
                    to={`/report/${id}`}
                    label={
                      <Tooltip fz={"sm"} label={name}>
                        <Text flex={1} size="sm" truncate="end">
                          {name}
                        </Text>
                      </Tooltip>
                    }
                    description={`From ${dayjs(created_at).utc().fromNow()}`}
                  />
                ),
              )}
            </MenuItem>
            {/* Must set  to={"/insight"} so we can automatically open parent NavLink when children NavLink is the active one */}
            <MenuItem label={"Insights"} icon={BiCollection} to={"/insight"}>
              {renderMenuItems(
                insightsList,
                "/insight",
                {
                  image: EmptyDataCatalogImage,
                  text: "You haven't created any insights yet.",
                },
                InsightMenuItem,
              )}
            </MenuItem>
            <MenuItem label={"Settings"} icon={BiCog} to={"/settings"} />
          </Stack>
          <Stack gap={"xs"} ta={"center"} mt={12}>
            <Text size={"xs"} c={"dimmed"}>
              Hey, {user.data?.email}
            </Text>
            <Button
              size={"compact-xs"}
              color="gray"
              variant="transparent"
              onClick={(e) => void signOut(e)}
            >
              Logout
            </Button>
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
