import { Table } from "@components/Table";
import { Page } from "@layout/Page";

import { useInsightsList } from "@api/useInsight";
import { ActionIcon, Box, Group, Text, Tooltip } from "@mantine/core";
import type { InsightList } from "@mm/shared/schemas/insights";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useMemo } from "react";
import { BiCollection } from "react-icons/bi";
import { NavLink } from "react-router-dom";

const mapArtifacts = (insights: InsightList) => {
  return insights.flatMap((insight) => {
    /* we reverse the inner artifacts array because we want to the the latest first
     * we only keep the data that we care about
     */
    return insight.artifacts.toReversed().map((artifact) => {
      return {
        ...artifact,
        insight: {
          id: insight.id,
          table_display_name: insight.table_display_name,
        },
        report: { name: insight.report?.name },
      };
    });
  });
};

const columnHelper =
  createColumnHelper<ReturnType<typeof mapArtifacts>[number]>();

const columns = [
  columnHelper.display({
    id: "open",
    cell: ({ row: { original: artifact } }) => (
      <Tooltip label="Open insight">
        <ActionIcon
          variant="light"
          component={NavLink}
          to={`/insight/${artifact.insight.id}?artifact=${artifact.id}`}
        >
          <BiCollection />
        </ActionIcon>
      </Tooltip>
    ),
  }),
  columnHelper.accessor("name", {
    cell: (info) => (
      <Box display={"flex"}>
        <Group gap={"xs"}>
          <Tooltip multiline maw={"25rem"} label={info.getValue()}>
            <Text truncate="end">{info.getValue()}</Text>
          </Tooltip>
        </Group>
      </Box>
    ),
    header: () => (
      <Text fw="bold" truncate="end">
        Insight
      </Text>
    ),
  }),
  columnHelper.accessor("insight.table_display_name", {
    cell: (info) => (
      <Box display={"flex"}>
        <Group gap={"xs"}>
          <Tooltip multiline maw={"25rem"} label={info.getValue()}>
            <Text truncate="end">{info.getValue()}</Text>
          </Tooltip>
        </Group>
      </Box>
    ),
    header: () => (
      <Text fw="bold" truncate="end">
        Data Preparation
      </Text>
    ),
  }),
  columnHelper.accessor("created_at", {
    cell: (info) => (
      <Box display={"flex"}>
        <Text truncate="end">
          {dayjs(info.getValue()).utc().format("DD MMM. YYYY [at] HH:mm")}
        </Text>
      </Box>
    ),
    header: () => (
      <Text fw="bold" truncate="end">
        Created at
      </Text>
    ),
  }),
];

export const InsightsList = () => {
  const { data, isPending } = useInsightsList();

  const mappedData = useMemo(
    () => (data ? mapArtifacts(data) : undefined),
    [data],
  );

  return (
    <Page
      title={
        <Text size="xl" fw={"900"}>
          List of insights
        </Text>
      }
    >
      <Table data={mappedData} isPending={isPending} columns={columns} />
    </Page>
  );
};
