import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "@mm/shared/endpoints/companion/auth";
import { LLMRecommendationsSchema } from "@mm/shared/schemas/recommendations";

export const GetStarterRecommendations = createRoute({
  method: "get",
  path: "/api/v1/recommendations",
  operationId: "getStarterRecommendations",
  request: {
    headers: SupabaseHeaderSchema,
    query: z.object({
      count: z
        .string()
        .pipe(z.coerce.number())
        .optional()
        .openapi({ type: "number" }),
    }),
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            recommendations: LLMRecommendationsSchema.array(),
          }),
        },
      },
      description: "Get starter recommendations",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
