import { Box, Card, Group, Skeleton, Stack, Text } from "@mantine/core";
import type { DetailedReportColumn } from "@mm/shared/schemas/reports";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Column } from "./Column";
import { Outro } from "./Outro";

const MotionStack = motion.create(Stack);

const MotionOutro = motion.create(Outro);

export const AnimatedColumns = ({
  columns,
}: {
  columns: DetailedReportColumn[];
}) => {
  const [screens, setScreens] = useState(
    columns.sort((first, second) => {
      if (first.table_name && second.table_name)
        return first.table_name.localeCompare(second.table_name);

      if (first.table_name) return -1;
      if (second.table_name) return 1;
      return 0;
    }),
  );

  const handleNext = () => {
    if (screens.length > 0) {
      setScreens((prev) => prev.slice(1));
    }
  };

  return (
    <Card flex={1} p={"xl"}>
      <Stack flex={1} justify="center">
        <Group flex={1} mah={500} gap={"xl"} align="stretch">
          {/* Left section */}
          <Stack flex={"0 0 15rem"} justify="flex-start">
            <Text size="xl" fw={"bold"}>
              Review columns
            </Text>
            <Text>
              I identified{" "}
              <Text span fw={"bold"}>
                {columns.length} columns
              </Text>{" "}
              for your data preparation.
            </Text>
            <Text>
              Let's go through them
              <br />
              together!
            </Text>
          </Stack>

          <Box flex={"0 0 20rem"} pos={"relative"}>
            <AnimatePresence mode="popLayout">
              {screens.length === 0 ? (
                <MotionOutro key={"outro"} />
              ) : (
                screens.map((screen, index) => {
                  const blur = index * 1;
                  const scale = 1 - index * 0.1;
                  const opacity = 1 - index * 0.2;
                  const xOffset = -index * 30;

                  return (
                    <MotionStack
                      key={`${screen.table_name}.${screen.name}`}
                      gap={"xs"}
                      w={"100%"}
                      h={"100%"}
                      pos={"absolute"}
                      initial={{
                        x: xOffset,
                        scale: scale,
                        opacity: opacity,
                      }}
                      animate={{
                        x: xOffset,
                        scale: scale,
                        filter: `blur(${blur}px)`,
                        zIndex: screens.length - index,
                        opacity: opacity,
                      }}
                      exit={{
                        scale: [1, 1.05, 1.05, 0.3],
                        x: [0, 0, 250, 250],
                        opacity: [1, 1, 1, 0],
                        transition: {
                          duration: 0.8,
                          times: [0, 0.2, 0.6, 1],
                          ease: "easeInOut",
                        },
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                      }}
                    >
                      <Column column={screen} handleNext={handleNext} />
                    </MotionStack>
                  );
                })
              )}
            </AnimatePresence>
          </Box>

          {/* Right section */}
          <Stack flex={1} justify="center" style={{ overflow: "hidden" }}>
            <Stack
              flex={1}
              mah={250}
              style={{
                border: "1px dashed var(--mantine-color-gray-4)",
                borderRadius: "var(--mantine-radius-md)",
                padding: "1rem",
                overflow: "hidden",
              }}
            >
              <Group justify="space-between">
                <Text size="lg" fw={500}>
                  Data preparation
                </Text>
                <Text size="sm" c="dimmed">
                  {columns.length - screens.length}/{columns.length} columns
                </Text>
              </Group>
              <Group
                flex={1}
                gap={"xs"}
                wrap="nowrap"
                style={{ overflow: "hidden" }}
                align="center"
              >
                <AnimatePresence mode="popLayout" initial={false}>
                  {[...Array(columns.length - screens.length).keys()]
                    .reverse()
                    .map((idx) => (
                      <MotionStack
                        key={idx}
                        mah={150}
                        flex={"0 1 4rem"}
                        p={"xs"}
                        gap={"xs"}
                        bg={"gray.2"}
                        style={{
                          borderRadius: "var(--mantine-radius-sm)",
                          overflow: "hidden",
                        }}
                        layout
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          delay: 0.7 /* Slightly less than duration of card exit animation */,
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                      >
                        {[...Array(50).keys()].map((idx) => (
                          <Skeleton
                            key={idx}
                            animate={false}
                            height={4}
                            mih={4}
                            radius="xl"
                          />
                        ))}
                      </MotionStack>
                    ))}
                </AnimatePresence>
              </Group>
            </Stack>
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
};
