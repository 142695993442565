import { client } from "@api/client";
import { supabase } from "@hooks/use-client";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import {
  GetSubscriptionStatus,
  NotifSubscribe,
  NotifUnsubscribe,
} from "@mm/shared/endpoints/companion/notification.routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type Sub = {
  preferences: {
    channel_types?: Record<PropertyKey, boolean>;
  } & Record<PropertyKey, unknown>;
};

export const useSubscription = () => {
  const queryClient = useQueryClient();

  const { data: subscriptionStatus, isLoading: isLoadingSubscription } =
    useQuery({
      queryKey: ["subscriptionStatus"],
      queryFn: async ({ signal }) => {
        const authHeader = await withAuthTokens(supabase);
        const { data, error } = await client.GET(GetSubscriptionStatus.path, {
          params: { ...authHeader },
          signal,
        });

        if (error) {
          throw error;
        }

        return data;
      },
    });

  const { mutate: toggleSubscription, isPending: isTogglingSubscription } =
    useMutation({
      mutationFn: async ({
        channel_type,
        subscribed,
      }: {
        channel_type?: string;
        subscribed: boolean;
      }) => {
        const authHeader = await withAuthTokens(supabase);
        console.log(channel_type);

        const url = subscribed ? NotifSubscribe.path : NotifUnsubscribe.path;
        const response = await client.POST(url, {
          params: {
            ...authHeader,
            query: { channelType: channel_type },
          },
        });

        if (response.error) {
          throw new Error(
            `Failed to ${subscribed ? "subscribe" : "unsubscribe"}: ${response.error.error}`,
          );
        }

        return { channel_type, subscribed };
      },
      onMutate: async ({ channel_type, subscribed }) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries({ queryKey: ["subscriptionStatus"] });

        // Snapshot the previous value
        const previousStatus = queryClient.getQueryData(["subscriptionStatus"]);

        // Optimistically update to the new value
        queryClient.setQueryData(["subscriptionStatus"], (old: Sub) => {
          if (!channel_type) {
            return {
              ...old,
              isSubscribed: subscribed,
              preferences: {
                ...old.preferences,
                channel_types: subscribed ? old.preferences.channel_types : {},
              },
            };
          } else {
            return {
              ...old,
              preferences: {
                ...old.preferences,
                channel_types: {
                  ...old.preferences.channel_types,
                  [channel_type]: subscribed,
                },
              },
            };
          }
        });

        // Return a context object with the snapshotted value
        return { previousStatus };
      },
      onError: (_err, _newTodo, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          ["subscriptionStatus"],
          context?.previousStatus,
        );
      },
    });

  return {
    isSubscribed: subscriptionStatus?.isSubscribed ?? false,
    preferences: (subscriptionStatus?.preferences ?? {}) as Sub["preferences"],
    isLoadingSubscription,
    toggleSubscription,
    isTogglingSubscription,
  };
};
