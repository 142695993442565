import { Center, Stack, Text } from "@mantine/core";
import type { Report } from "@mm/shared/schemas/reports";
import React, { useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { AddModal } from "./AddModal";
import { WrapperColumn } from "./Column";
import classes from "./Columns.module.css";

type Props = {
  report: Report;
};

export const AddColumn: React.FC<Props> = ({ report }) => {
  const [opened, setOpened] = useState(false);

  const onClick = () => {
    return !opened && setOpened(true);
  };

  return (
    <WrapperColumn
      mod={{ opened: opened ? "true" : "false" }}
      flex={0}
      className={classes.addColumn}
      onClick={onClick}
    >
      {opened ? (
        <AddModal setOpened={setOpened} report={report} />
      ) : (
        <Center flex={1} w={"8rem"}>
          <Stack align="center">
            <BiAddToQueue size={24} />
            <Text ta={"center"} size="sm">
              Add
              <br />
              columns
            </Text>
          </Stack>
        </Center>
      )}
    </WrapperColumn>
  );
};
