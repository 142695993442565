import { useClient } from "@hooks/use-client";
import {
  Card,
  Center,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import {
  ShortlistColumnsSchema,
  type FlowStep,
} from "@mm/shared/schemas/flows";
import { useQuery } from "@tanstack/react-query";
import { BiUpArrowAlt } from "react-icons/bi";

const useDataTableColumns = ({ columnIds }: { columnIds: number[] }) => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["dataTableColumns", ...columnIds],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("data_table_columns")
        .select(
          `
          id,
          name,
          data_type,
          is_primary,
          is_system,
          is_time,
          description,
          null_percentage,
          sample_values,
          unique_values
        `,
        )
        .in("id", columnIds);

      if (error) {
        throw new Error(error.message);
      }

      return data.map((data) => {
        return {
          ...data,
          sample_values: data.sample_values
            ? (data.sample_values as {
                count: number;
                value: unknown;
              }[])
            : null,
        };
      });
    },
  });
};

const Line = ({
  label,
  value,
  valueIcon = null,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  valueIcon?: React.ReactNode;
}) => {
  return (
    <Group justify="space-between" wrap="nowrap">
      <Text flex={1} size="sm" truncate="end">
        {label}
      </Text>
      <Group gap={4} wrap="nowrap">
        {valueIcon}
        <Text c={"dimmed"} size="sm" ff={"monospace"}>
          {value}
        </Text>
      </Group>
    </Group>
  );
};

export const ShortlistColumnsCompleted = ({ step }: { step: FlowStep }) => {
  const { data: columnsIds } = ShortlistColumnsSchema.safeParse(step.output);
  const { data, isPending } = useDataTableColumns({
    columnIds: columnsIds ?? [],
  });

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <Text>Perfect! We found some relevant data:</Text>
      <SimpleGrid cols={3}>
        {data?.map((column) => (
          <Card withBorder key={column.id}>
            <Stack gap={"xs"}>
              <Line
                label={
                  <Text span fw={"bold"}>
                    {column.name}
                  </Text>
                }
                value={"Frequency"}
                valueIcon={<BiUpArrowAlt color="var(--mantine-color-dimmed)" />}
              />
              <Stack gap={4}>
                {column.sample_values?.map(({ count, value }, index) => (
                  <Line
                    key={`${index}_${count}_${value}`}
                    label={String(value)}
                    value={count}
                  />
                ))}
              </Stack>
            </Stack>
          </Card>
        ))}
      </SimpleGrid>
    </Stack>
  );
};

export const ShortlistColumnsAwaitingInput = null;

export const ShortlistColumnsRunning = () => {
  return <Text>We're scanning through your data universe...</Text>;
};
