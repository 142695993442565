import { Text } from "@mantine/core";

export const CreateProcedureCompleted = null;
//return <Text>Analysis plan ready!</Text>

export const CreateProcedureAwaitingInput = null;

export const CreateProcedureRunning = () => {
  return <Text>We're crafting the perfect strategy...</Text>;
};
