import { useClient } from "@hooks/use-client";
import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { AnalyzeRequestSchema, type FlowStep } from "@mm/shared/schemas/flows";
import { useMutation } from "@tanstack/react-query";
import { BiCheck, BiRightArrowAlt } from "react-icons/bi";
import styles from "./AnalyzeRequest.module.css";

const parseStepOutput = (step: FlowStep) => {
  const { data: parsedInterpretations } = AnalyzeRequestSchema.safeParse(
    step.output,
  );

  return parsedInterpretations?.interpretations ?? [];
};
export const AnalyzeRequestCompleted = ({ step }: { step: FlowStep }) => {
  const interpretations = parseStepOutput(step);

  return (
    <Stack>
      <Text>Great! We know what you're looking for:</Text>
      <Group align="stretch">
        {interpretations.map((inter, index) => (
          <Card
            className={step.user_input === inter ? styles.selected : undefined}
            flex={1}
            key={`${index}_${inter}`}
          >
            <Stack h={"100%"} justify="space-between">
              <Stack gap={"xs"}>
                <Text c="dimmed">Interpretation {index + 1}</Text>
                <Text>{inter}</Text>
              </Stack>
              <Button
                className={
                  step.user_input === inter ? styles.disabled : undefined
                }
                style={{ cursor: "not-allowed" }}
                variant={step.user_input === inter ? "filled" : "light"}
                rightSection={
                  step.user_input === inter ? (
                    <BiCheck size={16} />
                  ) : (
                    <BiRightArrowAlt size={16} />
                  )
                }
              >
                Select{step.user_input === inter ? "ed" : ""}
              </Button>
            </Stack>
          </Card>
        ))}
      </Group>
    </Stack>
  );
};

const useSetInterpretation = (stepId: FlowStep["id"]) => {
  const { supabase } = useClient();

  return useMutation({
    mutationFn: async (interpretation: string | null) => {
      await supabase
        .from("flow_steps")
        .update({
          user_input: interpretation,
          status: "completed",
        })
        .eq("id", stepId);
    },
  });
};

export const AnalyzeRequestAwaitingInput = ({ step }: { step: FlowStep }) => {
  const { mutate, isPending, isSuccess } = useSetInterpretation(step.id);
  const interpretations = parseStepOutput(step);

  return (
    <Stack>
      <Text>Please select which interpretation matches your goal:</Text>
      <Group align="stretch">
        {interpretations.map((inter, index) => (
          <Card withBorder flex={1} key={`${index}_${inter}`}>
            <Stack h={"100%"} justify="space-between">
              <Stack gap={"xs"}>
                <Text c="dimmed">Interpretation {index + 1}</Text>
                <Text>{inter}</Text>
              </Stack>
              <Button
                loading={isPending || isSuccess}
                variant="light"
                rightSection={<BiRightArrowAlt size={16} />}
                onClick={() => mutate(inter)}
              >
                Select
              </Button>
            </Stack>
          </Card>
        ))}
      </Group>
    </Stack>
  );
};

export const AnalyzeRequestRunning = () => {
  return <Text>We're getting clear on your needs...</Text>;
};
