import { Box, Group, Text } from "@mantine/core";
import { NavLink } from "react-router-dom";
import classes from "./Steps.module.css";

type StepsProps = {
  active: number;
} & (
  | { active: 3; reportLink: string }
  | { active: 1 | 2; reportLink?: undefined }
);

export const Steps = ({ active, reportLink }: StepsProps) => {
  const steps = [
    "1. Objectives Definition",
    "2. Data Preparation",
    "3. Insights Builder",
  ];

  const isClickable = (index: number) => {
    // Step 1 is always clickable
    if (index === 0) return true;
    // Step 2 is only clickable when step 3 is active
    if (index === 1) return active === 3;
    // Step 3 is never clickable
    return false;
  };

  const getLinkTo = (index: number) => {
    if (index === 0) return "/";
    if (index === 1 && active === 3) return reportLink;
    // Step 3 is never clickable
    return "";
  };

  return (
    <Group gap={"md"} align="stretch">
      {steps.map((step, index) => {
        const clickable = isClickable(index);

        return (
          <Box
            key={step}
            pb={8}
            component={clickable ? NavLink : undefined}
            to={clickable ? getLinkTo(index) : ""}
            className={clickable ? classes.stepBox : classes.nonClickableStep}
            style={{
              userSelect: "none",
              borderBottom:
                index + 1 === active
                  ? "1px solid var(--mantine-primary-color-6)"
                  : "none",
            }}
          >
            <Text
              c={
                index + 1 === active
                  ? "var(--mantine-primary-color-6)"
                  : "dimmed"
              }
              size="xs"
            >
              {step}
            </Text>
          </Box>
        );
      })}
    </Group>
  );
};
