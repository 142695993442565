import type { ColumnTable, Table } from "arquero";
import { desc } from "arquero";
import { z } from "zod";

// Base parameter schemas
const filterParamsSchema = z.object({
  predicate: z.string(),
});

const deriveParamsSchema = z.object({
  as: z.string(),
  expr: z.string(),
});

const orderbyParamsSchema = z.object({
  by: z.string(),
  desc: z.boolean().optional(),
});

const groupbyParamsSchema = z.object({
  by: z.array(z.string()),
});

const rollupParamsSchema = z.object({
  aggregations: z.record(z.string()),
});

const countParamsSchema = z.object({
  as: z.string(),
});

const paramsParamsSchema = z.record(z.any());

const selectParamsSchema = z.object({
  columns: z.array(z.string()),
});

const foldParamsSchema = z.object({
  columns: z.array(z.string()),
});

const pivotParamsSchema = z.object({
  keys: z.union([z.array(z.string()), z.object({ key: z.string() })]),
  values: z.union([z.array(z.string()), z.object({ value: z.string() })]),
});

const renameParamsSchema = z
  .object({ columns: z.record(z.string()) })
  .or(z.record(z.string()).transform((columns) => ({ columns })));

// Main transformation schema
export const transformationSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("derive"),
    params: deriveParamsSchema,
  }),
  z.object({
    type: z.literal("filter"),
    params: filterParamsSchema,
  }),
  z.object({
    type: z.literal("groupby"),
    params: groupbyParamsSchema,
  }),
  z.object({
    type: z.literal("orderby"),
    params: orderbyParamsSchema,
  }),
  z.object({
    type: z.literal("rollup"),
    params: rollupParamsSchema,
  }),
  z.object({
    type: z.literal("params"),
    params: paramsParamsSchema,
  }),
  z.object({
    type: z.literal("count"),
    params: countParamsSchema,
  }),
  z.object({
    type: z.literal("select"),
    params: selectParamsSchema,
  }),
  z.object({
    type: z.literal("rename"),
    params: renameParamsSchema,
  }),
  z.object({
    type: z.literal("fold"),
    params: foldParamsSchema,
  }),
  z.object({
    type: z.literal("pivot"),
    params: pivotParamsSchema,
  }),
]);

export type Transformation = z.input<typeof transformationSchema>;
/**
 *
 * @param table arquero table
 * @param transformations the transformations to apply to the table
 * @returns an arquero table
 */
export function applyTransformations(
  table: ColumnTable,
  transformations: Transformation[],
): Table {
  let transformedTable = table;

  transformations.forEach((transformation) => {
    // console.log("Applying transformation", transformation);

    try {
      switch (transformation.type) {
        case "params": {
          transformedTable = transformedTable.params(
            transformation.params,
          ) as ColumnTable;
          break;
        }
        case "filter": {
          transformedTable = transformedTable.filter(
            transformation.params.predicate,
          );
          break;
        }
        case "derive": {
          const deriveParams = transformation.params;

          transformedTable = transformedTable.derive({
            [deriveParams.as]: deriveParams.expr,
          });
          break;
        }
        case "orderby": {
          const sortParams = transformation.params;
          const column = sortParams.by.startsWith("d.")
            ? sortParams.by.slice(2)
            : sortParams.by;

          const sort = sortParams.desc ? desc(column) : column;
          transformedTable = transformedTable.orderby(sort);
          break;
        }
        case "groupby": {
          const groupbyParams = transformation.params;
          transformedTable = transformedTable.groupby(groupbyParams.by);
          break;
        }
        case "rollup": {
          const rollupParams = transformation.params;
          transformedTable = transformedTable.rollup(rollupParams.aggregations);
          break;
        }
        case "count": {
          const countParams = transformation.params;
          transformedTable = transformedTable.count(countParams);
          break;
        }
        case "select": {
          const selectParams = transformation.params;
          transformedTable = transformedTable.select(selectParams.columns);
          break;
        }
        case "rename": {
          const renameParams = transformation.params;
          transformedTable = transformedTable.rename(renameParams.columns);
          break;
        }
        case "fold": {
          const foldParams = transformation.params;
          transformedTable = transformedTable.fold(foldParams.columns);
          break;
        }
        case "pivot": {
          const pivotParams = transformation.params;
          transformedTable = transformedTable.pivot(
            pivotParams.keys,
            pivotParams.values,
          );
          break;
        }
        default:
          return transformation satisfies never;
      }
    } catch (e) {
      if (e instanceof Error) {
        const msg = `Unable to apply ${JSON.stringify(transformation, undefined, 4)}: ${e.message}`;

        console.error(msg);
        throw new Error(msg);
      }

      throw e;
    }
  });

  return transformedTable;
}
