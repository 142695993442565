import { useState, useEffect } from "react";
import "@mantine/core/styles.css";

import { useClient } from "@hooks/use-client";
import { NavLink } from "react-router-dom";

import {
  Box,
  Button,
  Title,
  TextInput,
  rem,
  Text,
  Alert,
  Group,
  Stack,
  PinInput,
  Center,
  Space,
} from "@mantine/core";

import { BiAt, BiErrorCircle, BiLock } from "react-icons/bi";

export const Login = ({ usePINCode = false }) => {
  const { supabase, user, setUser } = useClient();
  const [result, setResult] = useState({
    error: false,
    message: null,
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [showPINInput, setShowPINInput] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let timer;
    if (countdown > 0 && showPINInput) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown, showPINInput]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult({ error: false, message: null });
    setUser((user) => ({ ...user, loading: true }));

    if (!email && !showPINInput) {
      setResult({ error: true, message: "Please enter your email" });
    } else if (!usePINCode && !password) {
      setResult({ error: true, message: "Please enter your password" });
    } else {
      try {
        let response;
        if (usePINCode) {
          if (!showPINInput) {
            response = await supabase.auth.signInWithOtp({
              email,
              options: {
                shouldCreateUser: false,
              },
            });
            if (response.error) {
              throw response.error;
            }
            setShowPINInput(true);
            setCountdown(30);
            setResult({
              error: false,
              message: "PIN code sent to your email.",
            });
          } else {
            response = await supabase.auth.verifyOtp({
              email,
              token: pinCode,
              type: "email",
            });
          }
        } else {
          response = await supabase.auth.signInWithPassword({
            email,
            password,
          });
        }

        if (response.error) {
          throw response.error;
        }

        if (!usePINCode || showPINInput) {
          setEmail("");
          setPassword("");
          setPinCode("");
          setShowPINInput(false);
        }
      } catch (error) {
        if (error.code === "otp_disabled") {
          setResult({
            error: true,
            message: "Email not signed up.",
          });
        } else {
          setResult({
            error: true,
            message: error.message,
          });
        }
      }
    }

    setUser((user) => ({ ...user, loading: false }));
  };

  const resendPIN = async () => {
    if (countdown === 0) {
      setResult({ error: false, message: null });
      try {
        const response = await supabase.auth.signInWithOtp({
          email,
          options: {
            shouldCreateUser: false,
            emailRedirectTo: import.meta.env.VITE_BASE_URL,
          },
        });
        if (response.error) throw response.error;
        setCountdown(30);
        setResult({ error: false, message: "PIN code resent to your email." });
      } catch (error) {
        setResult({
          error: true,
          message: error.message,
        });
      }
    }
  };

  return (
    <Box w={550} style={{ alignSelf: "flex-start" }} pt={50}>
      <Group align="center" justify="space-between">
        <Title order={1}>
          {usePINCode && showPINInput ? "Verify your account" : "Login"}
        </Title>
        <Button
          variant="transparent"
          size="xs"
          component={NavLink}
          to={usePINCode ? "/login" : "/login/pin"}
          onClick={() => {
            setResult({ error: false, message: null });
            setShowPINInput(false);
          }}
        >
          {usePINCode ? "password login" : "PIN code login"}
        </Button>
      </Group>
      <Text size="md" c="dimmed" mb={18}>
        {usePINCode && showPINInput
          ? `Enter the 6-digit PIN sent to ${email}`
          : "Enter your details."}
      </Text>
      <form autoComplete="on" onSubmit={handleSubmit}>
        <Stack spacing="md">
          {(!usePINCode || !showPINInput) && (
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              disabled={user.loading}
              radius="sm"
              size="lg"
              placeholder="Enter your email"
              rightSectionWidth={90}
              leftSection={<BiAt style={{ width: rem(18), height: rem(18) }} />}
            />
          )}
          {!usePINCode && (
            <TextInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              disabled={user.loading}
              radius="sm"
              size="lg"
              placeholder="Enter your password"
              rightSectionWidth={90}
              leftSection={
                <BiLock style={{ width: rem(18), height: rem(18) }} />
              }
            />
          )}
          {usePINCode && showPINInput && (
            <Center>
              <PinInput
                oneTimeCode
                length={6}
                type="number"
                value={pinCode}
                onChange={setPinCode}
                disabled={user.loading}
                size="lg"
                aria-label="Enter PIN code"
              />
            </Center>
          )}
          <Space h="md" />
          <Button type="submit" variant="light" loading={user.loading}>
            {usePINCode && !showPINInput ? "Send PIN Code" : "Login"}
          </Button>
          {usePINCode && showPINInput && (
            <Group gap={"xs"}>
              <Text size="sm">
                Didn't receive the PIN? Check your spam folder, or click
              </Text>
              <Group gap={"xs"}>
                <Button
                  p={countdown === 0 && 0}
                  size="compact-sm"
                  variant="transparent"
                  onClick={resendPIN}
                  disabled={countdown > 0}
                >
                  resend
                </Button>
                {countdown > 0 && (
                  <Text component="span" size="xs">
                    ({countdown}s)
                  </Text>
                )}
              </Group>
            </Group>
          )}
        </Stack>
      </form>

      {result.error && result.message.length && (
        <Alert
          color="red"
          variant="light"
          title="Error"
          mt={18}
          icon={<BiErrorCircle />}
        >
          {result.message}
        </Alert>
      )}
    </Box>
  );
};
