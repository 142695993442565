import type { TableFilters } from "@components/InsightBuilder/Table";
import { supabase } from "@hooks/use-client";
import { isValidStepName, lastStepIndex } from "@mm/shared/schemas/flows";
import { withAuthTokens } from "@mm/shared/endpoints/auth";
import {
  RetrieveFlowColumns,
  RetrieveFlowData,
} from "@mm/shared/endpoints/companion/flows.routes";
import { RunEnd2EndFlowRoute } from "@mm/shared/endpoints/end2end/run.routes";
import { FlowSchema, type Flow } from "@mm/shared/schemas/flows";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { client } from "./client";
import { toSearchParams } from "./client/searchParams";

export const flowQueryOptions = (flowId: string | undefined) =>
  queryOptions({
    queryKey: ["flow", { flowId }],
    queryFn: async () => {
      if (!flowId) return null;

      const { data, error } = await supabase
        .from("flows")
        .select("*, flow_steps(*)")
        .eq("id", flowId)
        .order("created_at", {
          referencedTable: "flow_steps",
          ascending: true,
        })
        .single();

      if (error) {
        throw new Error(error.message);
      }

      return FlowSchema.parse(data);
    },
  });

export const useFlow = (flowId: string | undefined) => {
  return useQuery({
    ...flowQueryOptions(flowId),
    enabled: !!flowId,
    refetchIntervalInBackground: false,
    refetchInterval: (query) => {
      const INTERVAL_MS = 1000;
      // Early return if no data or no steps
      if (!query.state.data?.flow_steps.length) {
        return INTERVAL_MS;
      }

      const steps = query.state.data.flow_steps;
      const latestStep = steps[steps.length - 1];

      if (!latestStep) {
        return INTERVAL_MS;
      }

      // Stop polling if there's an error or awaiting input
      if (latestStep.error) {
        return undefined;
      }

      // Stop polling if we reached the last step and it's completed
      if (isValidStepName(latestStep.name)) {
        const isLastStep = lastStepIndex === steps.length - 1;
        const isCompleted = latestStep.status === "completed";
        if (isLastStep && isCompleted) {
          return undefined;
        }
      }

      // Continue polling
      return INTERVAL_MS;
    },
  });
};

export const useStartFlow = () => {
  return useMutation({
    mutationFn: async (request: string) => {
      const authHeaders = await withAuthTokens(supabase);

      const { data } = await client.POST(RunEnd2EndFlowRoute.path, {
        params: { ...authHeaders },
        body: {
          userQuery: request,
        },
      });

      if (!data || data.status === "error") {
        throw new Error(data?.error);
      }

      return data;
    },
  });
};

export const useFlowColumns = (flowId: Flow["id"]) => {
  return useQuery({
    queryKey: ["flowColumns", { flowId }],
    staleTime: Infinity,
    queryFn: async () => {
      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.GET(RetrieveFlowColumns.path, {
        params: {
          ...authHeaders,
          path: { flowId: flowId },
        },
      });

      if (error) {
        console.error(
          `Failed to fetch results columns for flow ${flowId}: ${error.error}`,
        );
        throw new Error(`Failed to fetch results columns for flow ${flowId}`);
      }

      return data;
    },
  });
};

export const useFlowData = (
  flowId: Flow["id"],
  { sorting, filters }: { sorting: SortingState; filters: TableFilters },
) => {
  return useQuery({
    queryKey: ["flowData", { flowId }, { limit: 10_000, sorting, filters }],
    staleTime: Infinity,
    queryFn: async () => {
      const params = toSearchParams({ limit: 10_000, sorting, filters });

      const authHeaders = await withAuthTokens(supabase);
      const { data, error } = await client.GET(RetrieveFlowData.path, {
        params: {
          ...authHeaders,
          path: {
            flowId: flowId,
          },
          query: {
            ...params,
          },
        },
      });

      if (error) {
        console.error(
          `Failed to fetch data for flow ${flowId}: ${error.error}`,
        );
        throw new Error(`Failed to fetch data for flow ${flowId}`);
      }

      return data;
    },
  });
};
