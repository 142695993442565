import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import type { ArtifactDb, InsightDb } from "@mm/shared/schemas/insights";

import {
  insightOption,
  queryKeyForInsight,
  useInsightRestart,
} from "@api/useInsight";
import { useDisclosure } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { cloneElement, useState } from "react";

export const RestartModal = ({
  insight,
  artifact,
  children,
}: {
  insight: InsightDb;
  artifact?: ArtifactDb;
  children: React.ReactElement<{ onClick: (event: MouseEvent) => void }>;
}) => {
  const [opened, { close, open }] = useDisclosure(false);

  const queryClient = useQueryClient();
  /* This query is too fast and it looks odd in the frontend, we use this
   * state to artificially slow down the UI (button animation)*/
  const [showLoading, setShowLoading] = useState(false);
  const { mutate: restartInsighReq, isPending: isRestarting } =
    useInsightRestart();

  /* restart the insight at a given artifact position */
  const restartInsight = () => {
    restartInsighReq(
      { insight, artifact },
      {
        onSuccess: async (data) => {
          setShowLoading(true);
          await queryClient.invalidateQueries({
            queryKey: [...queryKeyForInsight(insight.id), "suggestions"],
            exact: false,
          });
          queryClient.setQueryData(insightOption(insight.id).queryKey, data);
          close();
          // artificially delay to avoid weird ui glitch when the popover is closing
          setTimeout(() => setShowLoading(false), 100);
        },
      },
    );
  };

  const handleChildClick = (event: MouseEvent) => {
    event.stopPropagation(); // Prevent event bubbling if necessary

    if (opened) {
      close();
    } else {
      open();
    }

    children.props.onClick(event);
  };

  const clonedChildren = cloneElement(children, {
    onClick: handleChildClick,
  });

  return (
    <>
      {clonedChildren}
      <Modal
        opened={opened}
        onClose={close}
        title={<Text fw={"bold"}>Restart Conversation</Text>}
      >
        <Stack>
          <Text>
            This will restart the conversation from the selected point.
          </Text>

          <Text>
            All subsequent messages and artifacts will be permanently deleted.
          </Text>
          <Group mt={"md"} justify="space-between">
            <Button
              color="red"
              loading={isRestarting || showLoading}
              onClick={restartInsight}
            >
              Restart
            </Button>
            <Button variant="white" color="black" onClick={close}>
              Cancel
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};
